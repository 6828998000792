import axios from "axios";
import React, { useEffect, useState } from "react";
import "./memberprofile.css";

const Memberprofile = () => {
  const [imageURL, setImageURL] = useState("");

  const url = "https://backend.goldengirlsonthemove.com";

  const [memberProfileInfo, setMemberProfileInfo] = useState({
    FirstName: "",
    Surname: "",
    OtherName: "",
    DateofBirth: "",
    IdNumber: "",
    Email: "",
    PhoneNumber: "",
    ProfilePic: "",
    BoxNo: "",
    ZipCode: "",
    City: "",
    County: "",
    Country: "",
  });

  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem("token"));

    if (storedToken) {
      axios
        .get(`${url}/auth/get_profiledetails`, {
          headers: { Authorization: `Bearer ${storedToken.token}` },
          withCredentials: true,
        })
        .then((result) => {
          const memberProfileData = result.data.result[0];

          if (memberProfileData) {
            setMemberProfileInfo({
              FirstName: result.data.result[0]["FIRSTNAME"],
              Surname: result.data.result[0]["SURNAME"],
              OtherName: result.data.result[0]["OTHERNAME"],
              DateofBirth: formatDateForInput(
                result.data.result[0]["DATE OF BIRTH"]
              ),
              IdNumber: result.data.result[0]["PASSPORT/ID"],
              Email: result.data.result[0]["EMAIL"],
              PhoneNumber: result.data.result[0]["PHONENUMBER"],
              ProfilePic: result.data.result[0]["PROFILE PICTURE"],
              BoxNo: result.data.result[0]["PO BOX"],
              ZipCode: result.data.result[0]["ZIPCODE"],
              City: result.data.result[0]["CITY"],
              County: result.data.result[0]["COUNTY"],
              Country: result.data.result[0]["COUNTRY"],
            });
          } else {
            console.error("Invalid response structure:", result.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    // Assuming you have the image URL available in memberProfileInfo.ProfilePic
    setImageURL(`${url}/Public/Images/${memberProfileInfo.ProfilePic}`);
  }, [memberProfileInfo.ProfilePic]);

  const formatDateForInput = (date) => {
    if (date && typeof date === "string") {
      const parts = date.split("-");
      if (parts.length === 3) {
        const monthIndex = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].indexOf(parts[1]);
        if (monthIndex !== -1) {
          const month = (monthIndex + 1).toString().padStart(2, "0");
          const formatted_date = `${parts[0]}-${month}-${parts[2]}`;
          return formatted_date;
        }
      }
    }
    return date;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setMemberProfileInfo({
        ...memberProfileInfo,
        ProfilePic: file,
      });

      // Display the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setImageURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    // Trigger file input click
    document.getElementById("profile_picInput").click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("FirstName", memberProfileInfo.FirstName);
    formData.append("Surname", memberProfileInfo.Surname);
    formData.append("OtherName", memberProfileInfo.OtherName);
    formData.append("DateofBirth", memberProfileInfo.DateofBirth);
    formData.append("IdNumber", memberProfileInfo.IdNumber);
    formData.append("Email", memberProfileInfo.Email);
    formData.append("PhoneNumber", memberProfileInfo.PhoneNumber);
    formData.append("profile_pic", memberProfileInfo.ProfilePic);
    formData.append("BoxNo", memberProfileInfo.BoxNo);
    formData.append("ZipCode", memberProfileInfo.ZipCode);
    formData.append("City", memberProfileInfo.City);
    formData.append("County", memberProfileInfo.County);
    formData.append("Country", memberProfileInfo.Country);

    try {
      const storedToken = JSON.parse(localStorage.getItem("token"));

      if (storedToken) {
        await axios
          .post(`${url}/auth/update_profile`, formData, {
            headers: {
              Authorization: `Bearer ${storedToken.token}`,
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          })
          .then((result) => {
            if (result.data.Status) {
              alert(result.data.Message);
            } else {
              alert(result.data.Error);
            }
          });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error, show error message, etc.
    }
  };

  //return part
  return (
    <div classname="profilePage">
      {/*  <div className="member__card">
        <div className="memberCardImage">
          <img src={imageURL} className="member__pic" alt="profile_pic" />
        </div>
        <h3>{memberCardName.memberName}</h3>
        <div className="socialLinks">
          <img src={FacebookIcon} alt="FacebookIcon" className="link"></img>
          <img src={TwitterIcon} alt="" className="link"></img>
          <img src={InstagramIcon} alt="" className="link"></img>
        </div>
      </div> */}
      <div className="memberProfileDiv">
        <form className="memberDetailsForm" encType="multipart/form-data">
          <h4>Personal Details</h4>
          <hr />
          <div className="profileNameSection">
            <div className="profileNameSectionPart">
              <p>First Name</p>
              <input
                type="text"
                className="profileFirstname"
                name="profilefirstname"
                value={memberProfileInfo.FirstName}
                onChange={(e) =>
                  setMemberProfileInfo({
                    ...memberProfileInfo,
                    FirstName: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="profileNameSectionPart">
              <p>Last Name</p>
              <input
                type="text"
                className="profileSurname"
                name="profilelastname"
                value={memberProfileInfo.Surname}
                onChange={(e) =>
                  setMemberProfileInfo({
                    ...memberProfileInfo,
                    Surname: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="profileNameSectionPart">
              <p>Other Name</p>
              <input
                type="text"
                className="profileMiddlename"
                placeholder="Other name"
                name="profilesecondname"
                value={memberProfileInfo.OtherName}
                onChange={(e) =>
                  setMemberProfileInfo({
                    ...memberProfileInfo,
                    OtherName: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>

          {/* Personal Information Part */}
          <div className="profilePersonalInfo">
            <div className="profilePersonalInfoPart">
              <p>Date of Birth</p>
              <input
                type="date"
                className="profileBirthdate"
                name="birthdate"
                value={memberProfileInfo.DateofBirth}
                onChange={(e) =>
                  setMemberProfileInfo({
                    ...memberProfileInfo,
                    DateofBirth: e.target.value,
                  })
                }
              />
            </div>
            <div className="profilePersonalInfoPart">
              <p>ID/Passport no.</p>
              <input
                type="text"
                className="profileIdNumber"
                name="nationalId"
                value={memberProfileInfo.IdNumber}
                onChange={(e) =>
                  setMemberProfileInfo({
                    ...memberProfileInfo,
                    IdNumber: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>

          {/**Email and Id section */}
          <div className="profileContactsSection">
            <div className="profileEmailPart">
              <p>Email</p>
              <input
                type="email"
                className="profileYourEmail"
                placeholder="Enter your email"
                name="email"
                autoComplete="off"
                value={memberProfileInfo.Email}
                onChange={(e) =>
                  setMemberProfileInfo({
                    ...memberProfileInfo,
                    Email: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="profilePhoneNumberPart">
              <p>Phone Number</p>
              <input
                type="text"
                className="profilePhoneNumber"
                name="phonenumber"
                value={memberProfileInfo.PhoneNumber}
                onChange={(e) =>
                  setMemberProfileInfo({
                    ...memberProfileInfo,
                    PhoneNumber: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>

          {/**Profile Picture*/}
          <div className="profilePicUploadDiv">
            <label htmlFor="profile_picture" className="profilePicUpload fas">
              <div className="img-wrap img-upload">
                <div className="image-container">
                  <img src={imageURL} alt="profilePic" />
                  <div className="button-wrap" onClick={handleButtonClick}>
                    <div className="button-before">Upload</div>
                  </div>
                </div>
              </div>
              <input
                type="file"
                id="profile_picInput"
                className="profile__picInput"
                name="profile_pic"
                onChange={handleFileChange}
              />
            </label>
            <button
              type="button"
              className="custom-upload-button"
              onClick={handleButtonClick}
            >
              Upload Photo
            </button>
          </div>

          {/* Location Part */}
          <h4>Location Details</h4>
          <hr />
          <div className="profileLocation">
            <div className="profileZipCountryDiv">
              <div className="profileZipDiv">
                <label>BOX No.</label>
                <input
                  type="alpanum"
                  className="profilePObox"
                  name="boxno"
                  value={memberProfileInfo.BoxNo}
                  onChange={(e) =>
                    setMemberProfileInfo({
                      ...memberProfileInfo,
                      BoxNo: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="profileZipDiv">
                <label>Zip Code</label>
                <input
                  type="alpanum"
                  className="profilePostOffice"
                  name="zipcode"
                  placeholder="00100"
                  value={memberProfileInfo.ZipCode}
                  onChange={(e) =>
                    setMemberProfileInfo({
                      ...memberProfileInfo,
                      ZipCode: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="profileCityDiv">
                <label>City</label>
                <input
                  type="text"
                  className="profileCity"
                  name="city"
                  value={memberProfileInfo.City}
                  onChange={(e) =>
                    setMemberProfileInfo({
                      ...memberProfileInfo,
                      City: e.target.value,
                    })
                  }
                ></input>
              </div>
            </div>

            <div className="profileCityCountyDiv">
              <div className="profileCountyDiv">
                <p>County</p>
                <input
                  type="text"
                  className="profileCounty"
                  name="county"
                  value={memberProfileInfo.County}
                  onChange={(e) =>
                    setMemberProfileInfo({
                      ...memberProfileInfo,
                      County: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="profileCountryDiv">
                <p>Country</p>
                <input
                  type="dropdown"
                  className="profileCountry"
                  name="country"
                  value={memberProfileInfo.Country}
                  onChange={(e) =>
                    setMemberProfileInfo({
                      ...memberProfileInfo,
                      Country: e.target.value,
                    })
                  }
                ></input>
              </div>
            </div>
          </div>

          <div className="registrationFormBtnDiv">
            <button
              type="submit"
              onClick={handleSubmit}
              className="registrationFormBtn"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Memberprofile;
