import React, { useEffect, useState } from "react";
import "./editmember.css";
import { useParams } from "react-router-dom";
import axios from "axios";

const EditMember = () => {
  const { id } = useParams();

  const url = "https://backend.goldengirlsonthemove.com";

  const [editMemberInfo, setEditMemberInfo] = useState({
    FirstName: "",
    Surname: "",
    OtherName: "",
    DateofBirth: "",
    IdNumber: "",
    Email: "",
    PhoneNumber: "",
    BoxNo: "",
    ZipCode: "",
    City: "",
    StreetName: "",
    County: "",
    Country: "",
  });

  useEffect(() => {
    axios
      .get(`${url}/auth/get_editdetails/${id}`)
      .then((result) => {
        if (result.data) {
          setEditMemberInfo({
            FirstName: result.data.Result[0]["FIRSTNAME"],
            Surname: result.data.Result[0]["SURNAME"],
            OtherName: result.data.Result[0]["OTHERNAME"],
            DateofBirth: formatDateForInput(
              result.data.Result[0]["DATE OF BIRTH"]
            ),
            IdNumber: result.data.Result[0]["PASSPORT/ID"],
            Email: result.data.Result[0]["EMAIL"],
            PhoneNumber: result.data.Result[0]["PHONENUMBER"],
            BoxNo: result.data.Result[0]["PO BOX"],
            ZipCode: result.data.Result[0]["ZIPCODE"],
            City: result.data.Result[0]["CITY"],
            StreetName: result.data.Result[0]["STREETNAME"],
            County: result.data.Result[0]["COUNTY"],
            Country: result.data.Result[0]["COUNTRY"],
          });
        } else {
          console.error("Invalid response structure:", result.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const formatDateForInput = (date) => {
    if (date && typeof date === "string") {
      const parts = date.split("-");
      if (parts.length === 3) {
        const monthIndex = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].indexOf(parts[1]);
        if (monthIndex !== -1) {
          const month = (monthIndex + 1).toString().padStart(2, "0");
          const formatted_date = `${parts[0]}-${month}-${parts[2]}`;
          return formatted_date;
        }
      }
    }
    return date;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (id) {
        await axios
          .put(`${url}/auth/update_memberInfo/${id}`, editMemberInfo)
          .then((result) => {
            if (result.data.Status) {
              alert(result.data.Message);
            } else {
              console.log(result.data.Error);
            }
          });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <div className="editMemberPage">
      <div className="memberProfileDiv">
        <form className="memberDetailsForm">
          <h4>Personal Details</h4>
          <hr />
          <div className="profileNameSection">
            <div className="profileNameSectionPart">
              <p>First Name</p>
              <input
                type="text"
                className="profileFirstname"
                name="profilefirstname"
                value={editMemberInfo.FirstName}
                onChange={(e) =>
                  setEditMemberInfo({
                    ...editMemberInfo,
                    FirstName: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="profileNameSectionPart">
              <p>Last Name</p>
              <input
                type="text"
                className="profileSurname"
                name="profilelastname"
                value={editMemberInfo.Surname}
                onChange={(e) =>
                  setEditMemberInfo({
                    ...editMemberInfo,
                    Surname: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="profileNameSectionPart">
              <p>Other Name</p>
              <input
                type="text"
                className="profileMiddlename"
                placeholder="Other name"
                name="profilesecondname"
                value={editMemberInfo.OtherName}
                onChange={(e) =>
                  setEditMemberInfo({
                    ...editMemberInfo,
                    OtherName: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>

          {/* Personal Information Part */}
          <div className="profilePersonalInfo">
            <div className="profilePersonalInfoPart">
              <p>Date of Birth</p>
              <input
                type="date"
                className="profileBirthdate"
                name="birthdate"
                value={editMemberInfo.DateofBirth}
                onChange={(e) =>
                  setEditMemberInfo({
                    ...editMemberInfo,
                    DateofBirth: e.target.value,
                  })
                }
              />
            </div>
            <div className="profilePersonalInfoPart">
              <p>ID/Passport no.</p>
              <input
                type="text"
                className="profileIdNumber"
                name="nationalId"
                value={editMemberInfo.IdNumber}
                onChange={(e) =>
                  setEditMemberInfo({
                    ...editMemberInfo,
                    IdNumber: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>

          {/**Email and Id section */}
          <div className="profileContactsSection">
            <div className="profileEmailPart">
              <p>Email</p>
              <input
                type="email"
                className="profileYourEmail"
                placeholder="Enter your email"
                name="email"
                autoComplete="off"
                value={editMemberInfo.Email}
                onChange={(e) =>
                  setEditMemberInfo({
                    ...editMemberInfo,
                    Email: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="profilePhoneNumberPart">
              <p>Phone Number</p>
              <input
                type="text"
                className="profilePhoneNumber"
                name="phonenumber"
                value={editMemberInfo.PhoneNumber}
                onChange={(e) =>
                  setEditMemberInfo({
                    ...editMemberInfo,
                    PhoneNumber: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>

          {/* Location Part */}
          <h4>Location Details</h4>
          <hr />
          <div className="profileLocation">
            <div className="profileZipCountryDiv">
              <div className="profileZipDiv">
                <label>BOX No.</label>
                <input
                  type="alpanum"
                  className="profilePObox"
                  name="boxno"
                  value={editMemberInfo.BoxNo}
                  onChange={(e) =>
                    setEditMemberInfo({
                      ...editMemberInfo,
                      BoxNo: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="profileZipDiv">
                <label>Zip Code</label>
                <input
                  type="alpanum"
                  className="profilePostOffice"
                  name="zipcode"
                  placeholder="00100"
                  value={editMemberInfo.ZipCode}
                  onChange={(e) =>
                    setEditMemberInfo({
                      ...editMemberInfo,
                      ZipCode: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="profileCityDiv">
                <label>City</label>
                <input
                  type="text"
                  className="profileCity"
                  name="city"
                  value={editMemberInfo.City}
                  onChange={(e) =>
                    setEditMemberInfo({
                      ...editMemberInfo,
                      City: e.target.value,
                    })
                  }
                ></input>
              </div>
            </div>
            <div className="profileStreetNameDiv">
              <p>Street Name</p>
              <input
                type="text"
                className="profileStreetName"
                placeholder="Avenue Road, Main Street"
                name="streetname"
                value={editMemberInfo.StreetName}
                onChange={(e) =>
                  setEditMemberInfo({
                    ...editMemberInfo,
                    StreetName: e.target.value,
                  })
                }
              ></input>
            </div>

            <div className="profileCityCountyDiv">
              <div className="profileCountyDiv">
                <p>County</p>
                <input
                  type="text"
                  className="profileCounty"
                  name="county"
                  value={editMemberInfo.County}
                  onChange={(e) =>
                    setEditMemberInfo({
                      ...editMemberInfo,
                      County: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="profileCountryDiv">
                <p>Country</p>
                <input
                  type="dropdown"
                  className="profileCountry"
                  name="country"
                  value={editMemberInfo.Country}
                  onChange={(e) =>
                    setEditMemberInfo({
                      ...editMemberInfo,
                      Country: e.target.value,
                    })
                  }
                ></input>
              </div>
            </div>
          </div>

          <div className="registrationFormBtnDiv">
            <button
              type="submit"
              onClick={handleSubmit}
              className="registrationFormBtn"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditMember;
