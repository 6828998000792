import React, { useState } from "react";
import "./reset.css";
import PasswordImg from "../../assets/password.png";
import axios from "axios";
import { useParams } from "react-router-dom";

const Reset = () => {
  const { id, token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError(
        <p
          className="required"
          style={{
            color: "red",
            padding: "1rem",
            border: "2px solid red",
            borderRadius: "5px",
            backgroundColor: "#ffe6e6",
          }}
        >
          Passwords did not match!
        </p>
      );
      return;
    }

    const url = "https://backend.goldengirlsonthemove.com";

    axios
      .post(`${url}/auth/reset-password/${id}/${token}`, {
        newPassword,
        confirmPassword,
      })
      .then((result) => {
        if (result.data.Status) {
          alert(result.data.Message);
        } else {
          alert(result.data.Message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div id="resetPassword">
      <form>
        <div className="reset_container">
          <div className="changePass__Inputs">
            <center>{error}</center>
            <div className="changePass__Input">
              <div className="input__parr">
                <p>New Password:</p>
              </div>
              <div className="input__part">
                <img src={PasswordImg} alt="" />
                <input
                  type="password"
                  name="new_password"
                  value={newPassword}
                  className="new_password"
                  placeholder="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="changePass__Input">
              <div className="input__parr">
                <p>Confirm Password:</p>
              </div>
              <div className="input__part">
                <img src={PasswordImg} alt="" />
                <input
                  type="password"
                  name="confirm_password"
                  value={confirmPassword}
                  className="confirm_password"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="reset__passwordBtn"
          >
            Reset
          </button>
          <div className="resetRedirects">
            <p className="goLogin">
              <a href="/login">Login</a>
            </p>
            <p className="goHome">
              <a href="/home">Back to Home</a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Reset;
