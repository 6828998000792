import React from "react";
import "./footer.css";
import FacebookIcon from "../../assets/facebook-icon.png";
import TwitterIcon from "../../assets/x.webp";
import InstagramIcon from "../../assets/instagram.webp";
import YouTubeIcon from "../../assets/youtube.webp";
import EmailIcon from "@mui/icons-material/Email";

const Footer = () => {
  return (
    <div className="footer">
      <div className="sb__footer section_padding">
        <div className="sb__footer-links">
          <div className="sb__footer-links_div">
            <h4>Quick Links</h4>
            <a href="./home" id="link">
              <p>Home</p>
            </a>
            <a href="./blog" id="link">
              <p>Blog</p>
            </a>
            <a href="./about" id="link">
              <p>About</p>
            </a>
            <a href="./home" id="link">
              <p>ContactUs</p>
            </a>
            <a href="./register" id="link">
              <p>Register</p>
            </a>
            <a href="/blog" id="link">
              <p>FAQ</p>
            </a>
          </div>
          <div className="sb__footer-links_div">
            <h4>Resources</h4>
            <a href="./about" id="link">
              <p>Pictorials</p>
            </a>
            <a href="./about" id="link">
              <p>Video Gallery</p>
            </a>
            <a href="./about" id="link">
              <p>Reports</p>
            </a>
          </div>
          <div className="sb__footer-links_div">
            <h4>Our Partners</h4>
            <a href="./contact">
              <p>Partner With GG-M</p>
            </a>
          </div>
          <div className="sb__footer-links_div">
            <h4>Address</h4>
            <p>5430-00100</p>
            <p>Kuwinda Road</p>
            <p>Nairobi, Kenya</p>
          </div>
          <div className="sb__footer-links_div">
            <h4>GG-M</h4>
            <div className="footerEmailStuff" onClick={() => window.location = 'mailto:info@goldengirlsonthemove.com'}>
              <EmailIcon /> info@goldengirlsonthemove.com
            </div>
            <div className="socialMedia">
              <a href="/home">
                <img
                  src={FacebookIcon}
                  alt="Golden Girls FaceBook"
                  className="socialMediaImg"
                />
              </a>
              <a href="/home">
                <img
                  src={TwitterIcon}
                  alt="Golden Girls Twitter"
                  className="socialMediaImg"
                />
              </a>
              <a href="/home">
                <img
                  src={InstagramIcon}
                  alt="Golden Girls Instagram"
                  className="socialMediaImg"
                />
              </a>
              <a href="/home">
                <img
                  src={YouTubeIcon}
                  alt="Golden Girls YouTube"
                  className="socialMediaImg"
                />
              </a>
            </div>
            <div>
              <a href="https://www.freepik.com" target="_blank" rel="noopener noreferrer">Designed by Freepik</a>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="sb__footer-below">
        <div className="sb__footer-copyright">
          <p>
            Copyright &#169; {new Date().getFullYear()} Golden Girls on the
            Move. All rights reserved.
          </p>
        </div>
        <div className="sb__footer-below_links">
          <a href="/termsofuse">
            <div>
              <p>Terms & Conditions</p>
            </div>
          </a>
          <a href="/privacy">
            <div>
              <p>Privacy Policy</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
