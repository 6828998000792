import React from "react";
import "./media.css";
import Green from "../../assets/green_logo.jpg";
import Gold from "../../assets/golden_logo.jpg";
import Black from "../../assets/black_logo.jpg";

const Media = () => {
  return (
    <div className="media__landing">
      <h2 className="mediapage-logo-header">LOGOS</h2>
      <div className="media_container">
        <img src={Green} className="image_item" alt="Media" />
        <img src={Gold} className="image_item" alt="Media" />
        <img src={Black} className="image_item" alt="Media" />
      </div>
    </div>
  );
};

export default Media;
