import React from "react";
import "./about.css";
/* import Placeholder from "../../assets/w_icon.png"; */
import One from "../../assets/1-transformed.png";
import Two from "../../assets/two-t.png";
import Three from "../../assets/three-t.png";
import Four from "../../assets/four_t.png";
import Five from "../../assets/five-transformed.png";
import Six from "../../assets/six-t.png";
import { useNavigate } from "react-router-dom";
import Mary from "../../assets/mary_amuyunzu.jpg";
import Teresia from "../../assets/Teresia.jpg";
import Patricia from "../../assets/Patricia.jpg";

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <section id="about">
      <div className="aboutIntro">
        <h1 className="aboutHead">
          We're empowering women aged 50+ to engage in sports activities as well
          as supporting young women in sports
        </h1>
        <h4 className="aboutHeadHook">
          GG-M is not just another sporting organization
        </h4>
        <h4 className="journeyHead">Our Journey</h4>
        <p className="aboutIntroPara">
          A group of women aged 50+ years have been running as recreational
          runners for the last 5 - 20 years consistently. However, during this
          process it has become apparent to them that there are several gaps and
          challenges facing this age group that require intervention.
        </p>
        <p className="aboutIntroPara">
          {" "}
          The Golden Girls on the Move (GG-M) Sports Club has been formed by
          this group to champion women’s participation in sports in the country
          and beyond.
        </p>
        <p className="aboutIntroPara">
          This is a membership Club for women aged 50+ years, which will have a
          two-pronged approach:
        </p>
        <ol className="aboutIntroList">
          <li>In-reach for its members</li>
          <li>Outreach for young female sportswomen</li>
        </ol>
      </div>
      <div className="companyDetails">
        <div className="registrationDetails">
          <h3 className="companyDetailsHead">Association Details</h3>
          {/* <h4>Registration Details</h4>
          <p className="registrationInfoPara">
            We are registered by the Registrar of Sports
          </p>{" "}
          <span>
            <button
              className="registrationDetailsBtn"
              onClick={() => navigate("/constitution")}
            >
              Registration Details.
            </button>
          </span>
          <p className="Pin">
            {" "}
            Our PIN is : <b>#########</b>
          </p> */}
          <h4 className="ourConstitution">Our constitution</h4>
          <p>Click on the button below to read our constitution</p>
          <span>
            <button
              className="ourConstitutionBtn"
              onClick={() => navigate("/constitution")}
            >
              Read Our Constitution
            </button>
          </span>
        </div>

        {/* Vision */}
        <div className="vision">
          <h2 className="ourVision">Our Vision</h2>
          <p className="ourVisionText">
            A nation with vibrant sportswomen at all ages.
          </p>
        </div>
        <div className="mission">
          <h2 className="ourMission">Our Mission</h2>
          <p className="ourMissionText">
            To nurture the active participation of women 50+ in sports while
            supporting young sportswomen to thrive in Kenya.
          </p>
        </div>

        {/* Membership rules*/}
        <div className="membership">
          <h1 className="membershipInfo">Membership</h1>
          <ol className="membershipList">
            <li>
              Membership is open to any woman aged 50+ years who is engaged in
              sports or who has an interest in sports in the country. Members
              will be required to pay an annual subscription fee of{" "}
              <b>KShs. 3,000.00</b> or as may be prescribed by the Management
              Committee from time to time. The sum shall become due and payable
              on the 1st day of July in each year by all members on the register
              of the Club on the preceding 30th day of June.
            </li>
            <li>
              Any person may be allowed to participate in the meetings and other
              activities of the Club as a guest but subject to such conditions
              as the Committee may prescribe.
            </li>
          </ol>
        </div>
      </div>
      {/* Goals */}
      <div id="goals">
        <div className="goalsInfo">
          <div className="goalsInfoHead">
            <h2 className="goalsHead">What we can achieve together</h2>
            <p className="goalsText">
              Golden Girls on the Move wants to create a society where women are
              encouraged to be active in sports and to support them in their
              physical, mental, spiritual and social needs. Our target is not
              only limited to encouraging women aged 50+ to be physically active
              but also to support young sportswomen across all sporting
              activities. Our goals include:
            </p>
          </div>
          <div className="goalsBars">
            <div className="goalsBar">
              <img src={One} alt="firstImg" className="numberImg" />
              <div className="goalsBarInfo">
                <h4>
                  Support and encourage women aged 50+ to engage in sports
                  activities
                </h4>
                <p>
                  Based on global evidence, the importance of exercise to women
                  in this age group especially in terms of improving their
                  physical and mental well can't be understated. We want to get
                  as many women as possible to engage in sports as well as
                  providing an opportunity for those who were initially active
                  in sports but stopped to be active once again
                </p>
              </div>
            </div>
            <div className="goalsBar">
              <img src={Two} alt="secondImg" className="numberImg" />
              <div className="goalsBarInfo">
                <h4>
                  Organize sports events to raise awareness on women in sports
                </h4>
                <p>
                  Women in sports face alot of challenges and very few
                  opportunities to get the spotlight on them. We aim to organize
                  sports events and provide this opportunity to various
                  sportswomen. This events will be open to various women sports
                  and provide a chance to encourage more women to start or
                  continue their sporting careers.
                </p>
              </div>
            </div>
            <div className="goalsBar">
              <img src={Three} alt="thirdImg" className="numberImg" />
              <div className="goalsBarInfo">
                <h4>Encourage philanthropic activities that target women</h4>
                <p>
                  These activities will be open to all and the aim will be to
                  address challenges these sportswomen face such as funding,
                  publicity, physical and mental health. We hope this will
                  activities will further improve awareness to challenges
                  sportwomen face.
                </p>
              </div>
            </div>
            <div className="goalsBar">
              <img src={Four} alt="fourImg" className="numberImg" />
              <div className="goalsBarInfo">
                <h4>Nurture talent among young among young sportswomen</h4>
                <p>
                  Our target is not only limited to women aged 50+. Part of our
                  activities will be targeted to young sportswomen who are
                  either in sports individually or as a team. We want to provide
                  the pedestal for them to improve their career or achieve the
                  personal milestones. We plan to work together with different
                  sportsteams and hold their hands when needed.
                </p>
              </div>
            </div>
            <div className="goalsBar">
              <img src={Five} alt="fiveImg" className="numberImg" />
              <div className="goalsBarInfo">
                <h4>
                  Provide an opportunity for Goden Girls to Learn and Thrive
                </h4>
                <p>
                  With our main target being Golden Girls (women aged 50+),
                  Golden Girls on the Move hopes to provide this women a
                  platform they can learn be it socially, professionally,
                  health-wise and/or mentally.
                </p>
              </div>
            </div>
            <div className="goalsBar">
              <img src={Six} alt="sixImg" className="numberImg" />
              <div className="goalsBarInfo">
                <h4>
                  Liase with local and international organizations on matters of
                  mutual interest
                </h4>
                <p>
                  Golden Girls on the Move will target to partner with other
                  organizations whose objectives align with our own should the
                  opportunity arise. This will provide us with a greater chance
                  of achieveing the thus stated goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="management">
        <h2>Our Team</h2>
        <div className="managementBars">
          <div className="managementBar">
            <img src={Mary} alt="memberImg" className="memberImg" />
            <h4>Dr. Mary Nyamongo</h4>
            <h4 className="memberTitle">Founder & Patron</h4>
            <p>
              The 50's and above can be the most liberating years for a woman.
              But they can also be the most trying. Unmet expectations, dealing with menopause,
              amidst other social, emotional and financial challenges. This is when GGM comes in.
              We provide a platform for women to learn, mentor, and re-invent themselves.
              The power of women-for-women is unleashed at GGM.
              Please join and be part of this incredible movement.
            </p>
          </div>
          <div className="managementBar">
            <img src={Patricia} alt="" className="memberImg" />
            <h4>Patricia Nyaundi</h4>
            <h4 className="memberTitle">Secretary</h4>
            <p>
              Getting to 50 represented a shifting of gears for me; a new and exciting phase of my life.
              I have enough to look back on and celebrate and learn from. Achievements that define me:
              family, career and social networks, all attributable to my connection with the Divine.
              Here at Golden Girls I sign up and commit to an active and healthy lifestyle.
              I show up for myself and the Golden girls are my squad, together we KeepMoving
            </p>
          </div>
          <div className="managementBar">
            <img src={Teresia} alt="" className="memberImg" />
            <h4>Teresia Ochieng'</h4>
            <h4 className="memberTitle">Treasurer</h4>
            <p>
              50 plus years is a beautiful season. It gives a woman more opportunities in terms of travel and self care.
              It is also a season where, to some, transitions have taken place, some may have been widowed,
              divorced, married or in a second marriage. It's a beautiful time to bond with women friends and keep
              moving to strengthen the bones and muscles.
              That's why GGM is the place to be, to socialise and grow stronger together.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
