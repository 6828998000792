import React from 'react';
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/footer';
import Register from '../components/Register/register';

const Registeration = () => {
  return (
	<div className="Home">
	  <Navbar />
	  <Register />
	  <Footer />
	</div>
  )
}

export default Registeration;
