import React, { useContext } from "react";
import "./item.css";
import { Link } from "react-router-dom";

const Item = ({ product }) => {
  if (!product) return null;

  return (
    <div className="item">
      <Link to={`/product/${product.id}`}>
        <img
          onClick={() => window.scrollTo(0, 0)}
          src={product.image}
          alt={product.name}
        />
      </Link>
      <div className="item_name_price">
        <p>{product.name}</p>
        <div className="item_prices">
          <div className="item_price">{product.price}</div>
        </div>
      </div>
      <Link to={`/product/${product.id}`}>
      <button
        className="view_options_button"
      >
        View Options
      </button>
      </Link>
    </div>
  );
};

export default Item;
