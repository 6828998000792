import React from "react";
import "./ggm_blog.css";
import BlogImg1 from "../../assets/blog_2.jpg";

//Photo by Fitsum Admasu on Unsplash

const Ggmblog = () => {
  return (
    <section id="ourBlog">
      <div className="ourBlogHeader">
        <h2 className="ourBlogHead">GG-M Blog</h2>
        <p className="ourBlogPara">
          Collection of stories to spear women ahead
        </p>
        <img src={BlogImg1} alt="ourBlogImg" className="ourBlogImg" />
      </div>
      <div className="blogContent">
        <div className="blogHeader">
          <h2 style={{ fontFamily: "Recoleta" }}>
            Physical Exercise at 50+: What benefits to expect.
          </h2>
        </div>
        <div className="healthBlogIntro">
          <p className="blogPara">
            Being physically active especially later in life can be challenging
            due to physical and time constraints. You get yourself asking
            whether it is really worth it. While the benefits of being
            physically active are known to many it is in our nature to overlook
            just how important these seemingly exhausting exercises can be.{" "}
            <br />
            <br />
            Yes, I understand, waking up at 6 or 5 am for a run can be
            overwhelming and going to the gym can also be a challenge in it
            self. We all have the paid membership when morale was high only for
            it to dwindle but maybe it is our mindset that is all wrong when it
            comes to exercise.
            <br />
            <br />
            One of the ways to overcome this mental block is to answer the
            question:{" "}
            <b>
              Why do I need to be physically active more so at 50 or older?
            </b>{" "}
            In this blog I will help ypu answer that question as well as help
            you figure out how you can overcome the biggest challenge: getting
            started or picking up from where you left.
            <br />
            <br />
            There is global evidence on the numerous benefits being physically
            active can do not only you health but also to your life in these
            aspects: socially, professionally and spiritually but first let us
            go through it benefits healthwise.
          </p>
        </div>
        <div className="benefitsContent">
          <div>
            <ol>
              <li>
                <h2 className="mainHealthHead">Health benefits</h2>
                <div>
                  <ol className="healthBenefitsList">
                    <li>
                      <h4>Physical Health</h4>
                      <p className="benefitsContentPara">
                        There is no denying that physical exercise boosts your
                        overall physical health be it through improve immunity,
                        lowering risk of cardiovascular diseases, improve muscle
                        and bone strenght and lastly lowering risk of
                        Non-communicable diseases such as arthritis, rheumatic
                        disease, cancers, diabetes, hypertension. The reality of
                        such issues become more apparent as we grow older and
                        physical exercise coupled with the right diet is the
                        best way to help us stay stronger for longer. <br />
                        <br />
                        When we exercise we are able to tax our body and get rid
                        of excess sugar, salt and fat leaving us leaner,
                        stronger and healthier. <br />
                        <br />
                        Quite important, is the abilty of physical exercise to
                        push your heart and lungs to their limits improving
                        blood flow, heart muscle strength which lowers risk of
                        strokes or more generally and as earlier mentioned
                        improving cardiovascular function.
                        <br />
                        <br /> Exercise also improves your sleep making your
                        deep sleep longer and thus improving your overall mood
                        and health.
                      </p>
                    </li>
                    <li>
                      <h4>Mental Health</h4>
                      <p className="benefitsContentPara">
                        Another part of your health that is often overlooked
                        that is very important is your mental health. In recent
                        times, <b>Mental Health</b> has become more talked about
                        but not the importance of physical exercise towards
                        improved mental health. More than the abs, and the toned
                        legs and shoulders exercise plays a vital role in
                        improving your mental health by:
                        <ul className="mentalHealthList">
                          <li>Releaving Stress</li>
                          <li>Improving your overall mood</li>
                          <li>Overcoming mental fatigue</li>
                          <li>Fighting Depression</li>
                        </ul>
                        Stress, Depression and feeling blown-out is something
                        that is not alien to most of us in this ever growing
                        modernised world. The more we become dettached from each
                        other the more we experience these issues. Physical
                        exercise is a great way to unwind and helping you
                        mentally.
                        <br />
                        <br />
                        Be it through a jog, an walk, a session in the gym or on
                        a treadmill or engaging in your favorite sports you can
                        help you mind and body relax from the mental pressure
                        that especially comes with age due to the increased
                        stress.
                        <br />
                        <br />
                        It is no lie that as we age we tend to worry more about
                        our health, work and professional career, relationships
                        and also as we battle loneliness from loosing family
                        members or our children finally moving out, and this can
                        take its toll on our mental health. Physical execise can
                        help you unpack all this and leave you feeling
                        refreshed.
                      </p>
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <h2 className="mainHealthHead">Social Benefits</h2>
                <p className="benefitsContentPara">
                  Pysical activities be it walking, running, playing netball,
                  tennis etc. provide us with a great opportunity to socialize
                  with people who share a similar passion with us.
                  <br />
                  <br />
                  It provides a platform that helps us bond with not only our
                  peers but with also those who are younger or older than us.
                  More often than not thorough physical exercise you can build a
                  community that is centered around passion towards a similar
                  activity.
                  <br />
                  <br />
                  Through such a community you can be able to discover new
                  interest, build new relationships and support each other.
                </p>
              </li>
              <li>
                <h2 className="mainHealthHead">Professional Benefits</h2>
                <p className="benefitsContentPara">
                  It goes without sating that you will meet different people
                  when you engage in physical activities such as playing a sport
                  or running/ walking a marathon. Most of the time when we
                  engage in this sporting activities we join a community and
                  this provides as an opportunity to meetpeople who are ahead or
                  behind us in our career path.
                </p>
              </li>
              <li>
                <h2 className="mainHealthHead">Spiritual Benefits</h2>
                <p className="benefitsContentPara">
                  Exercise helps improve you be in the present moment. Whether
                  it is yoga, a run, playing your favorite sport or taking a
                  walk, your mind and body are in the present. This improves
                  your overall energy, helps you become more aware of yourself
                  and the world around you which intern improves mood,
                  alleviates stress and worry making you more positive. You are
                  able to tap that spiritual energy and improve overall vibe
                  that defines your presence and how others feel about you and
                  perceive you to be.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ggmblog;
