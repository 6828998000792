import React, { useEffect, useState } from "react";
import "./membersmng.css";
import { Link } from "react-router-dom";
import axios from "axios";

const Membersmng = () => {
  const [members, setMembers] = useState([]);

  const url = "https://backend.goldengirlsonthemove.com";

  useEffect(() => {
    // Fetch member data from your API endpoint
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          axios
            .get(`${url}/auth/get_members`, {
              headers: { Authorization: `Bearer ${token.token}` },
              withCredentials: true,
            })
            .then((response) => {
              setMembers(response.data.members);
            });
        } catch (error) {
          console.error("Error fetching member data:", error);
        }
      }
    };

    fetchData();
  }, []);

  const approveMember = async (id) => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const response = await axios.post(
          `${url}/auth/approve_member`,
          { id },
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          }
        );
        console.log(response.data);

        // Optionally refresh the members list or update UI
        setMembers((prev) =>
          prev.map((member) =>
            member.id === id ? { ...member, approved: true } : member
          )
        );
      } catch (error) {
        console.error("Error approving member:", error);
      }
    }
  };

  const deleteMember = async (id) => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        await axios.delete(`${url}/auth/delete_member/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        });
        console.log(`Member with id ${id} deleted`);

        // Remove member from state
        setMembers((prev) => prev.filter((member) => member.id !== id));
      } catch (error) {
        console.error("Error deleting member:", error);
      }
    }
  };

  return (
    <div id="manage">
      <div className="manage__content">
        <ul className="manage__list">
          <li className="manageListItem">
            <Link to="/dashboard/addmember" style={{ color: "gold" }}>
              {" "}
              Add New Member
            </Link>
          </li>
          <li className="manageListItem">Edit Member Information</li>
          <li className="manageListItem">Search Member</li>
        </ul>
        <hr />
        <div className="membersTable">
          <h4 className="membersTableHeader">List of Registered Members</h4>
        </div>
        <div className="tableDiv">
          <table className="memberTable">
            <thead className="tableHeader">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Date of Birth</th>
                <th>Phone Number</th>
                <th>Communication</th>
                <th>Date Joined</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {members.map((member) => (
                <tr key={member.id}>
                  <td>{`${member.FirstName} ${member.LastName}`}</td>
                  <td>{member.Email}</td>
                  <td>{member.DateOfBirth}</td>
                  <td>{`${member.PhoneNumber}`}</td>
                  <td>{member.PreferredCommunication}</td>
                  <td>{member.DateJoined}</td>
                  <td>
                    <Link to={`/dashboard/edit/` + member.id}>
                      <button className="editMemberBtn">Edit</button>
                    </Link>
                    <button
                      className="deleteMemberBtn"
                      onClick={() => deleteMember(member.id)}
                    >
                      Delete
                    </button>
                    <button
                      className="approveMemberBtn"
                      onClick={() => approveMember(member.id)}
                    >
                      Approve
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Membersmng;
