import React, { useContext } from "react";
import "./cartitems.css";
import { ShopContext } from "../../context/shopcontext";
import ClearIcon from "@mui/icons-material/Clear";

const CartItems = () => {
  const { data_product, cartItems, removeFromCart } = useContext(ShopContext);

  // Filter products that are in the cart
  const cartProducts = data_product.filter((prod) => cartItems[prod.id] > 0);

  return (
    <div className="cartitems">
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {cartProducts.length > 0 ? (
        cartProducts.map((prod) => (
          <div className="cartitems-row" key={prod.id}>
            <div className="cartitems-format">
              <img
                src={prod.image}
                alt={prod.name}
                className="carticon-product-icon"
              />
              <p>{prod.name}</p>
              <p>${prod.price.toFixed(2)}</p>
              <button className="cartitems-quantity">
                {cartItems[prod.id]}
              </button>
              <p>${(prod.price * cartItems[prod.id]).toFixed(2)}</p>
              <ClearIcon
                className="cartitems-remove-icon"
                onClick={() => removeFromCart(prod.id)}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="cart-empty-message">
          <p>Your cart is empty!</p>
        </div>
      )}
    </div>
  );
};

export default CartItems;
