import React, { useEffect, useState } from "react";
import "./support.css";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Support = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const url = "https://backend.goldengirlsonthemove.com";

  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem("token"));

    if (storedToken) {
      axios
        .get(`${url}/auth/get_profiledetails`, {
          headers: { Authorization: `Bearer ${storedToken.token}` },
          withCredentials: true,
        })
        .then((result) => {
          const memberProfileData = result.data.result[0];

          if (memberProfileData) {
            setEmail(result.data.result[0]["EMAIL"]);
          } else {
            console.error("Invalid response structure:", result.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !subject || !message) {
      alert("Please fill all the fields");
      return;
    }

    const supportData = {
      name,
      email,
      subject,
      message,
    };

    try {
      const res = await axios.post(`${url}/api/support`, supportData);
      if (res.status === 200) {
        toast("Message sent successfully");
        setName("");
        setSubject("");
        setMessage("");
      } else {
        alert(
          "An error occurred while processing your request. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      toast(
        "An error occurred while processing your request. Please try again later."
      );
    }
  };

  return (
    <div className="member__support">
      <div className="top__links">
        <h2>Contact Help Desk</h2>
        <p>
          <a href="/myaccount">Dashboard</a> &gt;&gt;{" "}
          <a href="/myaccount/support">Support</a> &gt;&gt;{" "}
          <a href="/myaccount/support">Contact Us</a>
        </p>
      </div>
      <div className="support__formDiv">
        <form className="support__form">
          <div className="support__form__intro">
            <h2>Support Query!</h2>
            <h4>Fill the form below to raise any issues to the support team</h4>
          </div>
          <div className="formField">
            <label for="support__memberName" className="support__label">
              Full Name
            </label>
            <input
              type="text"
              className="support__input"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
          <div className="formField">
            <label for="support__memberSub" className="support__label">
              Subject
            </label>
            <input
              type="text"
              className="support__input"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            ></input>
          </div>
          <div className="formField">
            <label for="support__memberMsg" className="support__label">
              Message
            </label>
            <textarea
              type="textarea"
              className="support__input"
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <hr />
          <div className="submit__btnDiv">
            <button className="submit__btn" onClick={handleSubmit}>
              <div className="submit__btnInfo">
                <CheckBoxIcon style={{ color: "white" }} />
                <p>Submit</p>
              </div>
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Support;
