import React, { useState } from "react";
import "./changepassword.css";
import Logo from "../../assets/logo_t.png";
import EmailImg from "../../assets/email.png";
import axios from "axios";

const Changepassword = () => {
  const [resetEmail, setResetEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(resetEmail);

    const url = "https://backend.goldengirlsonthemove.com";

    axios
      .post(`${url}/auth/forgot_password`, { email: resetEmail })
      .then((result) => {
        if (result.data.Status) {
          alert(result.data.Message);
        } else {
          alert(result.data.Message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div id="changePassword">
      <form>
        <div className="reset_container">
          <div className="resetImageDiv">
            <img src={Logo} alt="resetImage" className="resetImage"></img>
          </div>
          <div className="reset__emailIntro">
            <div className="sideline"></div>
            <p className="reset__emailPara">
              You will receive a link to reset your password
            </p>
            <div className="sideline"></div>
          </div>
          <div className="reset__Input">
            <img src={EmailImg} alt="" />
            <input
              type="email"
              name="reset__email"
              value={resetEmail}
              className="password__reset-email"
              placeholder="Your email address"
              onChange={(e) => setResetEmail(e.target.value)}
            ></input>
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="reset__passwordBtn"
          >
            Reset
          </button>
          <div className="reset__Text">
            <div>
              <a href="/login">Login</a>
            </div>
            <span>
              Don't have an account? <a href="/register">Register Here</a>
            </span>
            <p></p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Changepassword;
