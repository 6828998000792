import React, { createContext, useState, useEffect } from "react";
import data_product from "../assets/Shop/data";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
  const cart = JSON.parse(localStorage.getItem("cart")) || {};
  return cart;
};

const ShopContextProvider = (props) => {
  const [cartItems, setCartItems] = useState(getDefaultCart);
  const [totalPrice, setTotalPrice] = useState(0);

  // Sync cartItems with localStorage
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
    calculateTotalPrice();
  }, [cartItems]);

  // Calculate total price
  const calculateTotalPrice = () => {
    const price = Object.entries(cartItems).reduce((total, [itemId, qty]) => {
      const product = data_product.find((item) => item.id === itemId);
      return product ? total + product.price * qty : total;
    }, 0);
    setTotalPrice(price);
    localStorage.setItem("totalPrice", price.toFixed(2));
  };

  const addToCart = (itemId) => {
    setCartItems((prev) => ({
      ...prev,
      [itemId]: (prev[itemId] || 0) + 1,
    }));
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => {
      const newCart = { ...prev };
      if (newCart[itemId] === 1) {
        delete newCart[itemId];
      } else {
        newCart[itemId]--;
      }
      return newCart;
    });
  };

  const contextValue = {
    data_product,
    cartItems,
    totalPrice,
    addToCart,
    removeFromCart,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
