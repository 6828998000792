import React, { useState } from "react";
import "./payment.css";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";

const Payment = () => {
  const [paymentNumber, setPaymentNumber] = useState("");

  const url = "https://backend.goldengirlsonthemove.com";

  const handlePayment = async (e) => {
    e.preventDefault();

    try {
      const storedToken = JSON.parse(localStorage.getItem("token"));

      console.log(paymentNumber);

      if (storedToken) {
        await axios
          .post(
            `${url}/auth/stk_push`,
            { paymentNumber },
            {
              headers: {
                Authorization: `Bearer ${storedToken.token}`,
              },
              withCredentials: true,
            }
          )
          .then((result) => {
            if (result.data.Status) {
              alert(result.data.Message);
            } else {
              alert(result.data.Error);
            }
          });
      }
    } catch (error) {
      console.error("Error making payment:", error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <section id="payment__details">
      <div className="payment_Links">
        <p>
          <a href="/myaccount">Dashboard</a> &gt;&gt;{" "}
          <a href="/myaccount/payment">Payment</a>
        </p>
      </div>
      <div className="payment__stepsDiv">
        <h3>Payment Details</h3>
        <div className="payment__steps">
          <div>
            <InfoIcon className="infoIcon" />
          </div>
          <div>
            <ul>
              <li>Enter your phone number to pay from</li>
              <li>Click on the submit button</li>
              <li>
                You will receive a prompt on your phone to enter your M-PESA pin
              </li>
              <li>Our membership fee is ksh. 3000.</li>
              <li>Enter your M-PESA pin</li>
              <li>You will receive a confirmation message on your phone</li>
              <li>You can view your receipts under receipts</li>
            </ul>
          </div>
        </div>
        <form className="paymentForm">
          <div>
            <label htmlFor="support__memberName" className="payment__label">
              Enter Phone Number
            </label>
            <input
              type="number"
              className="support__input"
              id="inputName"
              placeholder="Phone number"
              value={paymentNumber}
              onChange={(e) => setPaymentNumber(e.target.value)}
            ></input>
          </div>
          <div>
            <button
              type="submit"
              onClick={handlePayment}
              className="payment__Btn"
            >
              Make Payment
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Payment;
