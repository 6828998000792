import React from "react";
import "./slideritem.css";
import { Link } from "react-router-dom";

const RelatedItem = (props) => {
  return (
    <div className="related_item">
      <Link to={`/product/${props.id}`}>
        <img onClick={window.scrollTo(0, 0)} src={props.image} alt="" />
      </Link>
      <div className="related_item_name_price">
        <p>{props.name}</p>
      </div>
    </div>
  );
};

export default RelatedItem;
