import React, { useEffect } from "react";
import News from "../components/News/news";
import Navbar from "../components/Navbar/navbar";
import Footer from "../components/Footer/footer";
import Events from "../components/Events/events";

const NewsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <News />
      <Events />
      <Footer />
    </div>
  );
};

export default NewsPage;
