import React from "react";
import "./description.css";

const DescriptionBox = () => {
  return (
    <div className="descriptionBox">
      <div className="descriptionboxNavigator">
        <div className="descriptionboxNavBox">Description</div>
        <div className="descriptionboxNavBox fade">Reviews(0)</div>
      </div>
      <div className="desriptionboxDescription">
        <p>
          Comfortable track suit with elastic pants and coat. Light and easy to wear. Available in all sizes. Made from 100% cotton. Machine washable. Do not bleach. Do not tumble dry. Iron on low heat. Do not dry clean.
        </p>
      </div>
    </div>
  );
};

export default DescriptionBox;
