import React, { useState } from "react";
import "./events.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Events = () => {
  const [notifyEmail, setNotifyEmail] = useState("");

  const [error, setError] = useState(null);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear any previous errors
    setError(null);

    const url = "https://backend.goldengirlsonthemove.com";

    if (!validateEmail(notifyEmail)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      console.log("notifyEmail", notifyEmail);
      const res = await axios.post(`${url}/api/notify`, { notifyEmail });
      if (res.status === 200) {
        // alert("Email sent successfully");
        toast.success("Email sent successfully");
        setNotifyEmail("");
      } else {
        toast.error("An error occurred while processing your request. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        "An error occurred while processing your request. Please try again later."
      );
    }
  };

  return (
    <div className="events">
      <div className="events__insight_div">
        <h2>WHAT WE DO</h2>
        <h3>Latest & Upcoming Events</h3>
        <p>
          <a href="/blog">READ OUR BLOGS</a>
        </p>
        <p>
          <a href="/news">NEWS & REPORTS</a>
        </p>
      </div>
      <div className="events__articles_div">
        <div className="new_blog_articles">
          <h3>NEWS . FEBRUARY 8, 2024</h3>
          <h4>Golden Girls on The move launch</h4>
          <Link to="">Stay tuned for our launch. Coming soon!</Link>
          <div className="notifyLaunchDiv">
            <p>NOTIFY ME!</p>
            <input
              type="email"
              className="notify__email"
              name="notifyEmail"
              placeholder="Enter Your Email"
              value={notifyEmail}
              onChange={(e) => {
                setNotifyEmail(e.target.value)
              }}
            ></input>
            <button class="notifyBtn" onClick={handleSubmit}>Submit</button>
            <center>{error}</center>
          </div>
        </div>
        <hr />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Events;
