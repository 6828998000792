import React, { useEffect } from "react";
import Memberdashboard from "../components/MemberDashboard/memberdashboard";

const Memberpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Memberdashboard />
    </div>
  );
};

export default Memberpage;
