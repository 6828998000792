import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/footer';
import Contact from '../components/Contact/contact';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='contact'>
      <Navbar />
      <Contact />
      <Footer />
    </div>
  )
};

export default ContactUs;
