import Shirt from "./shirt1.png";
import Track from "./t1.png";
import Jacket from "./s1.png";
import Tracksuit from "./ts1.png";

let data_product = [
  {
    id: 1,
    name: "Golden Girls Tracksuit",
    image: Tracksuit,
    slug: "golden-girls-tracksuit",
    price: "Ksh.0",
    description:
      "Comfortable track suit with elastic pants and coat. Light and easy to wear. Available in all sizes. Made from 100% cotton. Machine washable. Do not bleach. Do not tumble dry. Iron on low heat. Do not dry clean.",
    size: ["S", "M", "L", "XL", "XXL"],
    color: ["Black", "White", "Ash Gray"],
    gender: "Female",
  },
  {
    id: 2,
    name: "Golden Girls Sports Shirt",
    image: Shirt,
    slug: "golden-girls-shirt",
    price: "Ksh.0",
    description:
      "High quality sports shirt made from 100% cotton. Machine washable. Do not bleach. Do not tumble dry. Iron on low heat. Do not dry clean.",
    size: ["S", "M", "L", "XL", "XXL"],
    color: ["Black", "White", "Purple"],
    gender: "Female",
  },
  {
    id: 3,
    name: "Golden Girls Tracksuit Pants",
    image: Track,
    slug: "golden-girls-tracksuit-pants",
    price: "Ksh.0",
    description:
      "Comfortable track suit pants with elastic waist. Light and easy to wear. Available in all sizes. Made from 100% cotton. Machine washable. Do not bleach. Do not tumble dry. Iron on low heat. Do not dry clean.",
    size: ["S", "M", "L", "XL", "XXL"],
    color: ["Black", "White", "Ash Gray"],
    gender: "Female",
  },
  {
    id: 4,
    name: "Golden Girls Tracksuit Jacket",
    image: Jacket,
    slug: "golden-girls-tracksuit-jacket",
    price: "Ksh.0",
    description:
      "Comfortable track suit jacket with elastic waist. Light and easy to wear. Available in all sizes. Made from 100% cotton. Machine washable. Do not bleach. Do not tumble dry. Iron on low heat. Do not dry clean.",
    size: ["S", "M", "L", "XL", "XXL"],
    color: ["Black", "White", "Ash Gray"],
    gender: "Female",
  },
];

export default data_product;
