import React, { useEffect, useState } from "react";
import "./contact.css";
import FacebookIcon from "../../assets/facebook-icon.png";
import TwitterIcon from "../../assets/x.webp";
import InstagramIcon from "../../assets/instagram.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const url = "https://backend.goldengirlsonthemove.com";

  const axiosContactData = async () => {
    const data = {
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      message: message,
    };

    await axios
      .post(`${url}/api/contact`, data)
      .then((res) => {
        if (res.status === 200) {
          toast("Message sent successfully");
          setName("");
          setEmail("");
          setPhoneNumber("");
          setMessage("");
        } else {
          toast(
            "An error occurred while processing your request. Please try again later."
          );
        }
      })
      .catch((err) => {
        console.error("Axios error:", err);
        const notify = () =>
          toast(
            "An error occurred while processing your request. Please try again later."
          );
        notify();
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || phoneNumber === "" || message === "") {
      alert("Please fill all the fields");
    } else {
      axiosContactData();
    }
  };

  return (
    <section id="contactPage">
      {/* <div className='companyName'>
			<p>--- Golden Girls on the Move ---</p>
		</div> */}
      <h2 className="contactHead">Contact Us</h2>
      <span className="contactDesc">
        Fill the contact form below to discuss any work opportunities or
        question you may have:
      </span>
      <form className="contactForm">
        <hr />
        <div className="contacts-name">
          <p>Full Name</p>
          <input
            type="text"
            className="name"
            placeholder="Your Name"
            name="your_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="contacts">
          <div className="contacts-email">
            <p>Email</p>
            <input
              type="email"
              className="email"
              placeholder="Your email"
              name="your_email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="contacts-phone">
            <p>PhoneNumber</p>
            <input
              type="tel"
              className="phoneNumber"
              placeholder="Your Phone number"
              name="your_number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="contacts-message">
          <p>Message</p>
          <textarea
            className="msg"
            name="message"
            rows="5"
            placeholder="Your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          className="contact-submitBtn"
        >
          Submit
        </button>
        <div className="socialLinks">
          <img src={FacebookIcon} alt="FacebookIcon" className="link"></img>
          <img src={TwitterIcon} alt="" className="link"></img>
          <img src={InstagramIcon} alt="" className="link"></img>
        </div>
      </form>
      <ToastContainer />
    </section>
  );
};

export default Contact;
