import React from "react";
import Changepassword from "../components/Changepassword/changepassword";
import Navbar from "../components/Navbar/navbar";

const Recoverpassword = () => {
  return (
    <div>
      <Navbar />
      <Changepassword />
    </div>
  );
};

export default Recoverpassword;
