import React from "react";
import Navbar from "../components/Navbar/navbar";
import Product from "../components/Product/product";

const ProductPage = () => {
  return (
    <div>
      <Navbar />
      <Product />
    </div>
  );
};

export default ProductPage;
