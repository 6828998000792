import React from "react";
import "./shop.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import Item from "../Item/item";
import data_product from "../../assets/Shop/data";

const Shop = () => {
  const cart_redirect = () => {
    window.location = "/cart";
  };

  return (
    <div className="shop">
      <div className="shopHeader">
        <h3>GG-M Merch</h3>
        <div className="cart_stuff">
          <SearchIcon style={{ marginRight: "10px" }} />
          <ShoppingCartIcon onClick={cart_redirect} />
          <div className="cart_count">0</div>
        </div>
      </div>
      <div className="shop_items">
        <div className="shop_items-item">
          {data_product.map((item, i) => {
            return (
              <Item product={item}
              /* key={i}
              id={item.id}
              name={item.name}
              image={item.image}
              price={item.price} */
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Shop;
