import React from "react";
import "./landing.css";
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <section id="landing">
      <div>
        <div className="dashboard__squares">
          <div className="dashboard__square">
            <Link to="/dashboard/manage" style={{ color: "gold" }}>
              <h4>Manage Members</h4>
            </Link>
          </div>
          <div className="dashboard__square">
            <h4>Upload Media Files</h4>
          </div>
          <div className="dashboard__square">
            <Link to="/dashboard/addadmin" style={{ color: "gold" }}>
              <h4>Create Admin</h4>
            </Link>
          </div>
          <div className="dashboard__square">
            <Link to="/dashboard/analytics" style={{ color: "gold" }}>
              <h4>View Analytics</h4>
            </Link>
          </div>
          <div className="dashboard__square">
            <Link to="/dashboard/addmember" style={{ color: "gold" }}>
              <h4>Add new Member</h4>
            </Link>
          </div>
          <div className="dashboard__square">
            <h4>Member Payment Details</h4>
          </div>
        </div>
        <hr />
        <div className="graphs">
          <h2>GRAPHS</h2>
        </div>
      </div>
    </section>
  );
};

export default Landing;
