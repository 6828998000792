import React, { useEffect } from "react";
import Media from "../components/Media/media";
import Navbar from "../components/Navbar/navbar";
import Footer from "../components/Footer/footer";

const Mediapage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <Media />
      <Footer />
    </div>
  );
};

export default Mediapage;
