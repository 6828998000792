import React, { useState } from "react";
import "./newsletter.css";
import axios from "axios";
import { toast } from "react-toastify";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);
    setSuccess(null);

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    const url = "https://backend.goldengirlsonthemove.com";

    try {
      const res = await axios.post(`${url}/api/subscribe`, { email });
      if (res.status === 200) {
        // toast.success("Thank you for subscribing!");
        toast.success("Thank you for subscribing!");
        setEmail("");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast("Unable to subscribe. Please try again later.");
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="newsletter">
      <h1>Receive Health and Exercise Tips in Your Email</h1>
      <p>Subscribe to our newsletter and stay updated!</p>
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      <div>
        <input
          type="email"
          placeholder="Your Email"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleSubmit}>Subscribe</button>
      </div>
    </div>
  );
};

export default Newsletter;
