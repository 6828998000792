import React, { useState } from "react";
import "./login.css";
import PasswordImg from "../../assets/password.png";
import EmailImg from "../../assets/email.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const Login = () => {
  const [values, setValues] = useState({
    email: "",
    loginPassword: "",
  });
  axios.defaults.withCredentials = true;

  const [error, setError] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoggingIn(true);

    // Save the submitted values
    const loginData = { ...values };

    // Clear any previous errors
    setError(null);

    const url = "https://backend.goldengirlsonthemove.com";

    axios
      .post(`${url}/auth/adminlogin`, loginData) // Update the URL to your verification route
      .then((result) => {
        if (result.status === 200) {
          const token = result.data.token;
          const userRole = result.data.role;
          localStorage.setItem(
            "token",
            JSON.stringify({
              token: token,
              role: userRole,
            })
          );
          navigate("/myaccount");
        } else {
          console.error("Invalid response structure:", result.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setError(err.response.data.Error);
        } else {
          setError("An error occurred. Please try again later.");
        }
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };
  return (
    <div className="login">
      <form>
        <div className="container">
          <div className="text-danger">{error && error}</div>
          <div className="header">
            <div className="loginText">Log In</div>
            <div className="underline"></div>
          </div>
          <div className="loginInputs">
            <div className="loginInput">
              <img src={EmailImg} alt="" />
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
              />
            </div>
            <div className="loginInput">
              <img src={PasswordImg} alt="" />
              <input
                type="password"
                name="password"
                onChange={(e) =>
                  setValues({ ...values, loginPassword: e.target.value })
                }
                placeholder="Password"
              />
            </div>
          </div>
          <div className="forgot-password">
            <p>
              Forgot Password? <a href="/forgot">Click Here</a>
            </p>
          </div>
          <div className="submitContainer">
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={isLoggingIn}
              className="submit"
            >
              {isLoggingIn ? "Logging in..." : "Login"}
            </button>
          </div>
          <div className="registrationText">
            <p>
              New? &nbsp;
              <a href="/register"> Register Here</a>
            </p>
            <a href="/home" className="backHomeLink">
              <HomeIcon />
              Home
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
