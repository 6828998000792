import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Blog from "./pages/blog";
import About from "./pages/aboutUs";
import Nopage from "./pages/nopage";
import Registeration from "./pages/registeration";
import ContactUs from "./pages/contactus";
import Loginpage from "./pages/loginpage";
import AdminPage from "./pages/adminpage";
import Membersmng from "./components/MembersMng/membersmng";
import CreateEvent from "./components/CreateEvent/createevent";
import Sendemail from "./components/SendEMail/sendemail";
import Analytics from "./components/Analytics/analytics";
import AdminProfile from "./components/AdminProfile/adminProfile";
import Landing from "./components/Landing/landing";
import Addmember from "./components/Addmember/addmember";
import EditMember from "./components/EditMember/editmember";
import Addadmin from "./components/Addadmin/addadmin";
import Memberpage from "./pages/memberpage";
import Memberprofile from "./components/Memberprofile/memberprofile";
import Memberlanding from "./components/MemberLanding/memberlanding";
import Payment from "./components/Payment/payment";
import Support from "./components/Support/support";
import Constitutionpage from "./pages/constitutionpage";
import Privacypage from "./pages/privacypage";
import Recoverpassword from "./pages/recoverpassword";
import Resetpassword from "./pages/resetpassword";
import NewsPage from "./pages/news";
import Mediapage from "./pages/mediapage";
import ShopPage from "./pages/shopPage";
import Product from "./components/Product/product";
import CartPage from "./pages/cart";
import ProductPage from "./pages/productPage";
import PrivateRoute from "./components/protection";
import TermsofusePage from "./pages/termsofusePage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/register" element={<Registeration />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/product" element={<ProductPage />}>
            <Route path=":productId" element={<Product />} />
          </Route>
          <Route path="/cart" element={<CartPage />} />
          <Route path="/events" element={<NewsPage />} />
          <Route path="/media" element={<Mediapage />} />
          <Route path="/login" element={<Loginpage />} />
          <Route path="/forgot" element={<Recoverpassword />} />
          <Route path="/reset/:id/:token" element={<Resetpassword />} />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute isAdmin={true}>
                <AdminPage />
              </PrivateRoute>
            }
          >
            <Route path="" element={<Landing />} />
            <Route path="/dashboard/manage" element={<Membersmng />} />
            <Route path="/dashboard/createevent" element={<CreateEvent />} />
            <Route path="/dashboard/sendemail" element={<Sendemail />} />
            <Route path="/dashboard/analytics" element={<Analytics />} />
            <Route path="/dashboard/adminprofile" element={<AdminProfile />} />
            <Route path="/dashboard/addmember" element={<Addmember />} />
            <Route path="/dashboard/edit/:id" element={<EditMember />} />
            <Route path="/dashboard/addadmin" element={<Addadmin />} />
          </Route>
          <Route
            path="/myaccount"
            element={
              <PrivateRoute>
                <Memberpage />
              </PrivateRoute>
            }
          >
            <Route path="" element={<Memberlanding />} />
            <Route path="/myaccount/profile" element={<Memberprofile />} />
            <Route path="/myaccount/payment" element={<Payment />} />
            <Route path="/myaccount/support" element={<Support />} />
          </Route>
          <Route path="/constitution" element={<Constitutionpage />}></Route>
          <Route path="/privacy" element={<Privacypage />}></Route>
          <Route path="/termsofuse" element={<TermsofusePage />}></Route>
          <Route path="*" element={<Nopage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
