import React from "react";
import "./privacy.css";

const Privacy = () => {
  return (
    <div id="privacy">
      <div className="summary_para">
        <h2>SUMMARY</h2>
        <p>
          Golden Girls on the Move (‘GG-M’, ‘we’, ‘us’) is the controller and
          responsible for protecting your personal data and handling it
          responsibly.
        </p>
        <p>
          This policy covers personal data that GG-M processes whenever you
          interact with us including when you engage with us online (such as
          on social media or by using our websites and apps),when you
          correspond with us (such as by email or over the phone) and ,when
          you become a registered member.
        </p>
        <p>
          Whilst interacting with us, you may decide to follow links to
          websites which are operated by third parties who control the
          collection and use of your personal data. In these situations, GG-M
          does not control how your personal data is processed and therefore
          you should read the privacy policy and cookie policy (as applicable)
          located on such third-party websites.{" "}
        </p>
      </div>
      <div className="personal_details">
        <h3>Personal Details we collect from you</h3>
        <p>
          We collect your personal information from your interactions with us,
          such as when you engage with us online (such as on social media or
          by using our websites and apps),when you correspond with us (such as
          by email or over the phone) and ,when you become a registered
          member.{" "}
        </p>
        <p>The personal data we collect from you includes:</p>
        <ul className="data-collected">
          <li>
            the name and contact details that you provide when you register as
            a member with us{" "}
          </li>
          <li>your browser or device information </li>
          <li>your communications with us </li>
          <li>
            information about your attendance to GG-M events. Photos and video
            footage of you may be taken at these events.
          </li>
          <li>
            information you provide us to comply with our site entry
            procedures{" "}
          </li>
          <li>
            as a member information about your payment number or any other
            payment details we might need to process the payment of the annual
            membership fee.
          </li>
        </ul>
      </div>
      <div className="processing-data">
        <h3>Why we process your personal data</h3>
        <p>
          This section explains why we process your personal data and our
          legal bases to doing so
        </p>
        <h4>Consent</h4>
        <p>
          In the instance you opt-in to communication directly for information
          relating to the association, its events and our partners, then we
          will provide such information to you by email or text or phone.
          <br />
          Wherever we rely on your consent to process personal data, you have
          a right to withdraw that consent.  Contact us on
          [support@goldengirlsonthemove.com] for details on how to exercise
          your right.
        </p>
        <h4>Legitimate interest</h4>
        <p>
          We process you data when necessary to pursue our legitimate
          interest. This includes:
        </p>
        <ul className="process-data-list">
          <li>administering any Membership you have with us</li>
          <li>tailoring our website and communications for you</li>
          <li>
            prevent abusive, discriminatory or otherwise inappopriate or
            offensive behaviour, whether in person or online
          </li>
          <li>to process payment and prevent any fraudulent transactions</li>
          <li>
            to determine which events best suit you and which activities you
            are interested in to better achieve our mission
          </li>
          <li>
            manage legal claims, compliance, regulatory or investigative
            matters
          </li>
          <li>
            administering programmes run by us or our partner agencies that
            you have chosen to participate in{" "}
          </li>
        </ul>
        <p className="right-to-object">
          You have a right to object to any processing that we undertake for
          our legitimate interests. You have a right to object to any
          processing that we undertake for our legitimate interests
        </p>
        <h4>Contract</h4>
        <p>
          We process your personal data when necessary for contractual
          reasons, such as to administer your account registration and
          competition entries and to provide products and services that you
          have requested.
        </p>
        <h4>Legal obligation</h4>
        <p>
          We process your personal data when necessary to comply with a legal
          obligation, such as to comply with a court order.
        </p>
        <h4>Sensitive personal data</h4>
        <p>
          Sensitive In limited circumstances, we may need to process your
          sensitive personal data, such as ethnicity data, health data,
          biometric data, or data about criminal offences. This will only ever
          be where we meet a lawful condition for such processing, such as
          where we have your consent, where the data has been made public by
          the data subject, or where processing is necessary for: the
          prevention or detection or unlawful acts, the substantial public
          interest, or legal claims.
        </p>
      </div>
      <div className="retaining-data">
        <h3>How long we retain your data</h3>
        <p>
          We retain personal data about your membership for as long as your
          account remains active, and for a limited period of time afterwards
          (in case you decide to reactivate your membership or have queries
          about it).
        </p>
        <p>
          We retain personal data relating to your purchases for several years
          from the date of the relevant transaction.  This is to understand
          your purchasing preferences and to meet our legal and contractual
          obligations.  If your purchase has enduring relevance (such as
          information on events and activities undertaken including
          photographs) then we’ll keep relevant information for as long as we
          remain responsible to you for that purchase.
        </p>
        <p>
          Where you have asked us not to send you direct marketing, we keep a
          record of that fact to ensure we respect your request in future. {" "}
        </p>
        <p>
          We also retain information with the potential to give rise to legal
          disputes for 7 years.{" "}
        </p>
      </div>
    </div>
  );
};

export default Privacy;
