import React, { useState } from "react";
import "./faq.css";

const Faq = () => {
  const accordionData = [
    {
      question: "What does Golden Girls On the Move do?",
      answer:
        "We are a sporting organization geared towards encouraging women aged 50+ to be physically active through sports as well as supporting young sportwomen.",
    },
    {
      question: "Who can become a member?",
      answer:
        "Membership is open to any woman aged 50+ who is seeking to discover or rediscover their interest in sports while also creating inititatives to support other sportswomen.",
    },
    {
      question: "How do I become a registered member?",
      answer:
        "Getting started is easy. Just click on Register fill in the details. You will receive an email with your login information. Finalise the payment and you are all done",
    },
    {
      question: "Is there a membership fee?",
      answer:
        "At this moment registration is free. Post launch GG-M will charge an annual membership fee of Ksh. 3000 to its members. This will help organize events and run the association. The fee is to be paid by members registered as of June 30th.",
    },
    {
      question: "How many sports does GG-M support?",
      answer:
        "GG-M is not limited to one sport. We will support women in all sports and encourage our members to explore various sporting activities to help them find one that suits them best.",
    },
  ];

  const [accordion, SetActiveAccordion] = useState(0);

  function toggleAccordion(index) {
    if (index === accordion) {
      SetActiveAccordion(-1);
      return;
    }
    SetActiveAccordion(index);
  }

  return (
    <section id="FAQ">
      <>
        <div className="faq__head">
          <span className="accordion__title">Frequently Asked Questions</span>
          <h1>Let's answer some of your questions.</h1>
        </div>
        <div className="faq__container">
          <div className="accordion__faq">
            {accordionData.map((item, index) => (
              <div
                className="accordion__simple"
                key={index}
                onClick={() => toggleAccordion(index)}
              >
                <div className="accordion__faq-heading">
                  <h3 className={accordion === index ? "active" : ""}>
                    {item.question}
                  </h3>
                  <div>
                    {accordion === index ? (
                      <>
                        <span className="verticle">-</span>
                      </>
                    ) : (
                      <>
                        <span className="verticle">+</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="accordion__para">
                  <p className={accordion === index ? "active" : "inactive"}>
                    {item.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    </section>
  );
};

export default Faq;
