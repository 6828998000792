import React, { useState } from "react";
import "./addadmin.css";
import axios from "axios";

const Addadmin = () => {
  const [admin, setAdmin] = useState({
    adminname: "",
    adminemail: "",
    adminphonenumber: "",
    adminaddress: "",
    adminidnumber: "",
    adminpic: "",
  });

  const url = "https://backend.goldengirlsonthemove.com";

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${url}/auth/createadmin`, admin)
      .then((result) => console.log(result.data))
      .catch((err) => console.log(err));
  };

  return (
    <div className="addAdmin">
      <div className="addAdminDiv">
        <h4
          style={{
            margin: "1rem auto",
            textAlign: "center",
            fontSize: "1.5rem",
            fontFamily: "Recoleta",
            padding: "1rem",
          }}
        >
          Create Admin
        </h4>
        <form className="addAdminForm" onSubmit={handleSubmit}>
          <div className="formField">
            <label for="inputName" className="inputLabel">
              Name
            </label>
            <input
              type="text"
              className="inputField"
              id="inputName"
              placeholder="Enter Name"
              onChange={(e) =>
                setAdmin({ ...admin, adminname: e.target.value })
              }
            ></input>
          </div>
          <div className="formField">
            <label for="inputName" className="inputLabel">
              Email
            </label>
            <input
              type="email"
              className="inputField"
              id="inputAdminName"
              placeholder="Enter Email"
              autoComplete="off"
              onChange={(e) =>
                setAdmin({ ...admin, adminemail: e.target.value })
              }
            ></input>
          </div>
          <div className="formField">
            <label for="inputName" className="inputLabel">
              Phone Number
            </label>
            <input
              type="text"
              className="inputField"
              id="adminNumber"
              placeholder="+254712345678"
              onChange={(e) =>
                setAdmin({ ...admin, adminphonenumber: e.target.value })
              }
            ></input>
          </div>
          <div className="formField">
            <label for="inputName" className="inputLabel">
              Address
            </label>
            <input
              type="text"
              className="inputField"
              id="inputAddress"
              placeholder="123 Street Name"
              onChange={(e) =>
                setAdmin({ ...admin, adminaddress: e.target.value })
              }
            ></input>
          </div>
          <div className="formField">
            <label for="inputId" className="inputLabel">
              ID Number
            </label>
            <input
              type="text"
              className="inputField"
              id="inputId"
              onChange={(e) =>
                setAdmin({ ...admin, adminidnumber: e.target.value })
              }
            ></input>
          </div>
          <div className="formField">
            <label for="inputProfilePic" className="inputLabel">
              Profile Picture
            </label>
            <input
              type="file"
              className="inputField"
              id="inputProfilePic"
              onChange={(e) => setAdmin({ ...admin, adminpic: e.target.value })}
            ></input>
          </div>
          <div className="formField">
            <button type="submit" className="addAdminBtn">
              Submit Details
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Addadmin;
