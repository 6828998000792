import React, { useEffect, useState } from "react";
import "./memberdashboard.css";
import axios from "axios";
import { Outlet, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Face3Icon from "@mui/icons-material/Face3";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EventIcon from "@mui/icons-material/Event";
import HelpIcon from "@mui/icons-material/Help";
import EmailIcon from "@mui/icons-material/Email";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";

const Memberdashboard = () => {

  const url = "https://backend.goldengirlsonthemove.com";
  const handleLogout = () => {
    // Check if the token exists in localStorage before removing it
    const token = localStorage.getItem("token");
    if (token) {
      localStorage.removeItem("token");
    }

    // Navigate to the login page
    navigate("/login");
  };

  const MemberSidebarData = [
    {
      title: "Dashboard",
      icon: <DashboardIcon sx={{ color: "#FC0" }} />,
      Link: "/myaccount",
      cName: "nav-text",
    },
    {
      title: "Profile",
      icon: <Face3Icon sx={{ color: "#FC0" }} />,
      Link: "/myaccount/profile",
      cName: "nav-text",
    },
    {
      title: "Messages",
      icon: <EmailIcon sx={{ color: "#FC0" }} />,
      Link: "/myaccount/profile",
      cName: "nav-text",
    },
    {
      title: "Payment",
      icon: <AccountBalanceWalletIcon sx={{ color: "#FC0" }} />,
      Link: "/myaccount/payment",
      cName: "nav-text",
    },
    {
      title: "Activities",
      icon: <EventIcon sx={{ color: "#FC0" }} />,
      Link: "/myaccount",
      cName: "nav-text",
    },
    {
      title: "Support",
      icon: <HelpIcon sx={{ color: "#FC0" }} />,
      Link: "/myaccount/support",
      cName: "nav-text",
    },
    {
      title: "Log Out",
      icon: <LogoutIcon htmlColor="red" />,
      cName: "nav-text",
      onClick: handleLogout,
    },
  ];

  const [userProfile, setUserProfile] = useState({});
  const [sidebar, setSideBar] = useState(false);

  const navigate = useNavigate();

  const showSideBar = () => setSideBar(!sidebar);

  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem("token"));

    if (storedToken) {
      axios
        .get(`${url}/auth/get_navdetails`, {
          headers: { Authorization: `Bearer ${storedToken.token}` },
          withCredentials: true,
        })
        .then((result) => {
          const userProfileData = result.data.userProfile; // Update this based on your actual API response structure

          if (userProfileData) {
            setUserProfile(userProfileData);
          } else {
            console.error("Invalid response structure:", result.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <div className="dashboard-container">
      <div className="top_nav">
        <div className="userProfileInfo">
          <div className="menuSIdeBarDiv">
            <Link to="#" className="menu__bars">
              {sidebar ? (
                <CloseIcon onClick={showSideBar} />
              ) : (
                <MenuIcon onClick={showSideBar} />
              )}
            </Link>
            <div
              className="mobMenu"
              style={{ display: sidebar ? "flex" : "none" }}
            >
              <nav className="mob-menu">
                <ul className="mob-menu-items" onClick={showSideBar}>
                  <li>
                    {MemberSidebarData.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={item.cName}
                          onClick={item.onClick}
                        >
                          {item.Link ? (
                            <Link to={item.Link}>
                              {item.icon}
                              <span>{item.title}</span>
                            </Link>
                          ) : (
                            <div className="logout__div">
                              {item.icon}
                              <span>{item.title}</span>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <p>Hi, {userProfile.FirstName}</p>
        </div>
        <div className="userInfo">
          <NotificationsIcon className="userNotificationIcon" />
          <div className="profileInfo">
            <AccountCircleIcon className="userAccountCircleIcon" />
            <div className="profileDropdown">
              <ul>
                <li onClick={() => navigate("/myaccount/profile")}>
                  View Profile
                </li>
                <li onClick={() => navigate("/myaccount/payment")}>
                  Make Payment
                </li>
                <li onClick={handleLogout}>Logout</li>
              </ul>
            </div>
            {/* <button className="loginBtn" onClick={handleLogout}>
              <a href="/login">Logout</a>
            </button> */}
          </div>
        </div>
      </div>
      <div className="outer__content">
        <nav className="nav-menu">
          <ul className="nav-menu-items">
            <li>
              {MemberSidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName} onClick={item.onClick}>
                    {item.Link ? (
                      <Link to={item.Link}>
                        {item.icon}
                        <span>{item.title}</span>
                      </Link>
                    ) : (
                      <div>
                        {item.icon}
                        <span>{item.title}</span>
                      </div>
                    )}
                  </li>
                );
              })}
            </li>
          </ul>
        </nav>

        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Memberdashboard;
