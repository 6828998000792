import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

const PrivateRoute = ({ children, isAdmin }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const url = "https://backend.goldengirlsonthemove.com";

    if (token) {
      axios
        .get(`${url}/auth/get_navdetails`, {
          headers: { Authorization: `Bearer ${token.token}` },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            setIsAuthenticated(true);
            const userProfileData = response.data.userProfile;
            setUserRole(userProfileData.Role);
          }
        })
        .catch((error) => {
          console.error("Token verification failed", error);
          setIsAuthenticated(false);
        });
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // or a spinner
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (isAdmin && userRole !== "admin") {
    return <Navigate to="/myaccount" />;
  }

  return children;
};

export default PrivateRoute;
