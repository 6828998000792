import React from "react";
import "./intro.css";
import { Link } from "react-router-dom";
//import Woman from "../../assets/smiling.png";
//import Background from "../../assets/run.jpg";
import JoinImg from "../../assets/join.webp";

const Intro = () => {
  return (
    <section id="intro">
      <>
        <div className="introContent">
          <div className="intro__Info">
            <h2 className="introHead">Golden Girls on the Move</h2>
            <span className="introText">
              Golden Girls on the Move is a non-political non-profit association
              whose mission is to nurture the active participation of women 50+
              in sports while supporting young sportswomen in Kenya.
            </span>
            <div className="buttonContainer">
              <button type="button" className="firstBtn">
                <Link to="/about">About Us</Link>
              </button>
              <button type="button" className="introBtn">
                <Link to="/register">Become a member</Link>
              </button>
            </div>
          </div>
        </div>
      </>
      <div className="join">
        <div className="joinInvitation">
          <div className="joinInfo">
            <h2 className="joinHead">New Members are Welcome!</h2>
            <p className="joinText">
              Here at GG-M we are always open to new members. We will help you
              improve your life in these aspect : <b>physically</b>,{" "}
              <b>mentally</b>, <b>socially</b> and <b>spiritually</b>. Join us
              on this journey to create change not only your in life but also to
              the lives of other women. <br />
              <br />
            </p>
            <button className="joinBtn">
              <a href="/register">Register Now</a>
            </button>
          </div>
          <div className="joinImgDiv">
            <img src={JoinImg} alt="joinImg" className="joinImg"></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
