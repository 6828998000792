import React from "react";
import "./register.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const years = [];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [];

  const firstYear = new Date().getFullYear() - 49;
  let i = 50;

  while (i) {
    years.unshift(firstYear - i);
    i--;
  }

  let dy = 31;

  while (dy) {
    days.unshift(dy);
    dy--;
  }

  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [secondname, setSecondName] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [country, setCountry] = useState("");
  const [boxno, setPOBox] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [county, setCounty] = useState("");
  const [city, setCity] = useState("");
  const [month, setSelectedMonth] = useState("");
  const [year, setSelectedYear] = useState("");
  const [day, setSelectedDay] = useState("");
  const [passwd, setPassword] = useState("");
  const [isFriendOrColleague, setIsFriendOrColleague] = useState(false);
  const [isFacebook, setFacebook] = useState(false);
  const [isYouTube, setYouTube] = useState(false);
  const [isTikTok, setTikTok] = useState(false);
  const [isGoldenMember, setGoldenMember] = useState(false);
  const [isGGMEvent, setGGMEvent] = useState(false);
  const [isOther, setOther] = useState(false);
  const [preferredCommunication, setPreferredCommunication] = useState(""); // Set a default value
  const [isInAgreement, setInAgreement] = useState(false);
  const [hasReadPrivacy, setReadPrivacy] = useState(false);
  const role = "member";

  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const url = "https://backend.goldengirlsonthemove.com";

  //Navigate

  const navigate = useNavigate();

  /*  const generateRandomPassword = (length) => {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }
    return password;
  }; */

  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;

  //Post registeration Info
  const axiosPostData = async () => {
    const dateJoined = formattedDate;
    const birthdate = `${year}-${month}-${day}`;

    const postData = {
      FirstName: firstname,
      LastName: lastname,
      OtherName: secondname,
      Email: email,
      MemberPassword: passwd,
      PhoneNumber: phonenumber,
      NationalID: nationalId,
      CountyState: county,
      City: city,
      POBox: boxno,
      ZipCode: zipcode,
      Country: country,
      DateOfBirth: birthdate,
      FriendorColleague: isFriendOrColleague,
      Facebook: isFacebook,
      YouTube: isYouTube,
      TikTok: isTikTok,
      GoldenMember: isGoldenMember,
      GGMEvent: isGGMEvent,
      Other: isOther,
      PreferredCommunication: preferredCommunication,
      Agreement: isInAgreement,
      ReadPrivacy: hasReadPrivacy,
      DateJoined: dateJoined,
      Role: role,
    };

    axios
      .post(`${url}/auth/register`, postData)
      .then((result) => {
        if (result.status === 200) {

          toast.success("Registration successful. Your application is under review.");
          setTimeout(function () {
            navigate("/login");
          }, 3000); // execute after 2 seconds
        } else {
          const registerError = result.data.Error;
          console.log("Error:", registerError);
          toast.error(registerError);
          setIsSubmitting(false);
        }
      })
      .catch((err) => {
        console.error("Axios error:", err);
        toast.error(
          "An error occurred while processing your request. Please try again later."
        );
        setIsSubmitting(false);
      });
  };

  /* Handle Submit Function */
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const selectedValue = e.target.value || "any";
    setPreferredCommunication(selectedValue);

    if (!isInAgreement) {
      setError(
        <p
          className="required"
          style={{
            color: "red",
            padding: "2rem",
            border: "2px solid red",
            borderRadius: "5px",
            backgroundColor: "#ffe6e6",
          }}
        >
          Kindly agree to our rules to register.
        </p>
      );
      setIsSubmitting(false);
      return;
    }

    if (!hasReadPrivacy) {
      setError(
        <p
          className="required"
          style={{
            color: "red",
            padding: "2rem",
            border: "2px solid red",
            borderRadius: "5px",
            backgroundColor: "#ffe6e6",
          }}
        >
          Kindly agree to our privacy policy
        </p>
      );
      setIsSubmitting(false);
      return;
    }

    if (!passwd) {
      setError(
        <p
          className="required"
          style={{
            color: "red",
            padding: "2rem",
            border: "2px solid red",
            borderRadius: "5px",
            backgroundColor: "#ffe6e6",
          }}
        >
          Password required
        </p>
      );
      setIsSubmitting(false);
      return;
    }

    if (passwd.length < 8) {
      setError(
        <p
          className="required"
          style={{
            color: "red",
            padding: "2rem",
            border: "2px solid red",
            borderRadius: "5px",
            backgroundColor: "#ffe6e6",
          }}
        >
          Password should be at least 8 characters long
        </p>
      );
      setIsSubmitting(false);
      return;
    }

    if (passwd !== document.querySelector(".confirmPassword").value) {
      setError(
        <p
          className="required"
          style={{
            color: "red",
            padding: "2rem",
            border: "2px solid red",
            borderRadius: "5px",
            backgroundColor: "#ffe6e6",
          }}
        >
          Passwords do not match
        </p>
      );
      setIsSubmitting(false);
      return;
    }

    if (!email) {
      setError(
        <p
          className="required"
          style={{
            color: "red",
            padding: "2rem",
            border: "2px solid red",
            borderRadius: "5px",
            backgroundColor: "#ffe6e6",
          }}
        >
          Email required
        </p>
      );
      setIsSubmitting(false);
      return;
    }

    try {
      const result = await axios.get(`${url}/auth/check_email`, {
        params: { email: email },
      });

      if (result.data.Status) {
        // Assuming result.data.Status indicates email exists
        setError(
          <p
            className="required"
            style={{
              color: "red",
              padding: "2rem",
              border: "2px solid red",
              borderRadius: "5px",
              backgroundColor: "#ffe6e6",
            }}
          >
            Email already exists. Kindly login or recover your password.
          </p>
        );
        setIsSubmitting(false);
        return; // Stop further execution if email exists
      }
    } catch (err) {
      console.error("Axios error:", err);
      setError(
        <p
          className="required"
          style={{
            color: "red",
            padding: "2rem",
            border: "2px solid red",
            borderRadius: "5px",
            backgroundColor: "#ffe6e6",
          }}
        >
          An error occurred while checking the email. Please try again later.
        </p>
      );
      return; // Stop further execution in case of an error
    }

    // Posting form data
    setError("");
    axiosPostData();
  };

  /* Return section containing registration form */
  return (
    <section id="registerationPage">
      <div className="registrationInfo">
        <div className="registrationRequirements">
          <h1 className="registrationRequirementsHead">
            Who can become a member?
          </h1>
          <ol className="registrationRequirementsList">
            <li>
              <p>
                Membership is open to any woman aged 50+ years who is engaged in
                sports or who has an interest in sports in the country.
              </p>
            </li>
            <li>
              <p>
                Registered member will be required to pay an annual amount of
                ksh. 3000 period begin 1st July for those registered by 30th
                June.
              </p>
            </li>
          </ol>
        </div>

        <h1 className="registrationInfoHead">How do I become a member?</h1>
        {/* <p className='registrationInfotext'>To become a registered member kindly follow the steps below:</p> */}

        <div className="registrationInfoBar">
          <span>
            To become a registered member kindly fill your details in the form
            provide below(<b>*Required</b>)
          </span>
        </div>
        <div className="registerFormDiv">
          <form className="registrationForm">
            <h4>Name Section</h4>
            <div className="nameSection">
              <div className="nameSectionPart">
                <p>First Name*</p>
                <input
                  type="text"
                  className="firstname"
                  placeholder="First Name"
                  name="firstname"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                ></input>
              </div>
              <div className="nameSectionPart">
                <p>Other Name</p>
                <input
                  type="text"
                  className="middlename"
                  placeholder="Other name"
                  name="secondname"
                  value={secondname}
                  onChange={(e) => setSecondName(e.target.value)}
                ></input>
              </div>
              <div className="nameSectionPart">
                <p>Last Name*</p>
                <input
                  type="text"
                  className="surname"
                  placeholder="Surname"
                  name="lastname"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                ></input>
              </div>
            </div>

            {/* Personal Information Part */}
            <h4>Date of Birth</h4>
            <div className="personalInfo">
              <div className="monthDayPart">
                <div className="personalInfoPart">
                  <p>Month*</p>
                  <select
                    className="month"
                    name="month"
                    value={month}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    <option value=""></option>
                    {months.map((code, index) => (
                      <option key={index} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="personalInfoPart">
                  <p>Day*</p>
                  <select
                    className="day"
                    name="day"
                    value={day}
                    onChange={(e) => setSelectedDay(e.target.value)}
                  >
                    <option value=""></option>
                    {days.map((code, index) => (
                      <option key={index} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="personalInfoPart">
                <p>Year*</p>
                <select
                  className="year"
                  name="year"
                  value={year}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option value=""></option>
                  {years.map((code, index) => (
                    <option key={index} value={code}>
                      {code}
                    </option>
                  ))}
                </select>
              </div>
              <div className="IdPart">
                <p>ID/Passport no.*</p>
                <input
                  type="text"
                  className="IdNumber"
                  name="nationalId"
                  value={nationalId}
                  onChange={(e) => setNationalId(e.target.value)}
                ></input>
              </div>
            </div>

            <h4 className="contact__head">Contact Information</h4>

            <div className="contactsSection">
              <div className="emailPhoneInputPart">
                <div className="emailPart">
                  <h4>Email*</h4>
                  <input
                    type="email"
                    className="yourEmail"
                    placeholder="Enter your email"
                    name="email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </div>
                <div className="phonenumberSection">
                  <div className="contactSelectionPart">
                    <div className="phoneNumberPart">
                      <h4>Phone Number</h4>
                      <input
                        type="text"
                        className="PhoneNumber"
                        name="phonenumber"
                        value={phonenumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Location Part */}
            <h4>Where are you from?</h4>
            <div className="Location">
              <div className="zipCountryDiv">
                <div className="boxZipDiv">
                  <div className="zipDiv">
                    <label>BOX No.*</label>
                    <input
                      type="alpanum"
                      className="PObox"
                      name="boxno"
                      value={boxno}
                      onChange={(e) => setPOBox(e.target.value)}
                    ></input>
                  </div>
                  <div className="zipDiv">
                    <label>Zip Code*</label>
                    <input
                      type="alpanum"
                      className="postOffice"
                      name="zipcode"
                      placeholder="00100"
                      value={zipcode}
                      onChange={(e) => setZipCode(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="cityDiv">
                  <p>City</p>
                  <input
                    type="text"
                    className="city"
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  ></input>
                </div>
              </div>
              {/*  <div className="streetNameDiv">
                <p>Street Name</p>
                <input
                  type="text"
                  className="streetName"
                  placeholder="Avenue Road, Main Street"
                  name="streetname"
                  value={streetname}
                  onChange={(e) => setStreetName(e.target.value)}
                ></input>
              </div> */}

              <div className="cityCountyDiv">
                <div className="countyDiv">
                  <p>County*</p>
                  <input
                    type="text"
                    className="county"
                    name="county"
                    value={county}
                    onChange={(e) => setCounty(e.target.value)}
                  ></input>
                </div>
                <div className="countryDiv">
                  <p>Country</p>
                  <input
                    type="text"
                    className="Country"
                    name="country"
                    placeholder="Kenya"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            {/*Password Section*/}
            <h4>Password</h4>
            <div className="password_requirements">
              Should be atleast 8 characters long
            </div>
            <div className="passwordPart">
              <div className="firstPasswordPart">
                <p>Password*</p>
                <input
                  type="password"
                  className="password"
                  name="password"
                  value={passwd}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </div>
              <div className="confirmPasswordPart">
                <p>Confirm Password*</p>
                <input type="password" className="confirmPassword"></input>
              </div>
            </div>

            {/*Feedback Collection Part */}
            <div className="Feedback">
              <h4>Where did you hear about us?</h4>
              <div className="feedbackCheckDiv">
                <input
                  type="checkbox"
                  className="feedbackInfo"
                  name="feedbackInfo"
                  value="friendOrColleague"
                  checked={isFriendOrColleague}
                  onChange={(e) => setIsFriendOrColleague(e.target.checked)}
                />
                <p>A friend or Colleague</p>
              </div>
              <div className="feedbackCheckDiv">
                <input
                  type="checkbox"
                  className="feedbackInfo"
                  name="feedbackInfo"
                  value="Facebook"
                  checked={isFacebook}
                  onChange={(e) => setFacebook(e.target.checked)}
                ></input>
                <p>Facebook</p>
              </div>
              <div className="feedbackCheckDiv">
                <input
                  type="checkbox"
                  className="feedbackInfo"
                  name="feedbackInfo"
                  value="YouTube"
                  checked={isYouTube}
                  onChange={(e) => setYouTube(e.target.checked)}
                ></input>
                <p>YouTube</p>
              </div>
              <div className="feedbackCheckDiv">
                <input
                  type="checkbox"
                  className="feedbackInfo"
                  name="feedbackInfo"
                  value="TikTok"
                  checked={isTikTok}
                  onChange={(e) => setTikTok(e.target.checked)}
                ></input>
                <p>Tik Tok</p>
              </div>
              <div className="feedbackCheckDiv">
                <input
                  type="checkbox"
                  className="feedbackInfo"
                  name="feedbackInfo"
                  value="TikTok"
                  checked={isGoldenMember}
                  onChange={(e) => setGoldenMember(e.target.checked)}
                ></input>
                <p>GG-M Member</p>
              </div>
              <div className="feedbackCheckDiv">
                <input
                  type="checkbox"
                  className="feedbackInfo"
                  name="feedbackInfo"
                  value="GGMEvent"
                  checked={isGGMEvent}
                  onChange={(e) => setGGMEvent(e.target.checked)}
                ></input>
                <p>GG-M Events</p>
              </div>
              <div className="feedbackCheckDiv">
                <input
                  type="checkbox"
                  className="feedbackInfo"
                  name="feedbackInfo"
                  value="Other"
                  checked={isOther}
                  onChange={(e) => setOther(e.target.checked)}
                ></input>
                <p>Other</p>
              </div>
            </div>

            {/* Communication Method Part */}
            <div className="communication">
              <h4>Prefered way of communication</h4>
              <div className="commBars">
                <input
                  type="radio"
                  className="commMethod"
                  name="communication"
                  value="email"
                  checked={preferredCommunication === "email"}
                  onChange={(e) => setPreferredCommunication("email")}
                />
                <p>Email</p>
              </div>
              <div className="commBars">
                <input
                  type="radio"
                  className="commMethod"
                  name="communication"
                  value="phone"
                  checked={preferredCommunication === "phone"}
                  onChange={(e) => setPreferredCommunication("phone")}
                />
                <p>Phone</p>
              </div>
              <div className="commBars">
                <input
                  type="radio"
                  className="commMethod"
                  name="communication"
                  value="any"
                  checked={preferredCommunication === "any"}
                  onChange={(e) => setPreferredCommunication("any")}
                />
                <p>Any</p>
              </div>
            </div>

            {/* Rules for members */}
            <div className="themRules">
              <h2>Membership Rules</h2>
              <hr />
              <ol className="rulesList">
                <li>Membership is only open to women aged 50 or older</li>
                <li>
                  After the launch a membership fee of ksh.3000 or its equivalent in any currency
                  is required to remain registered.
                </li>
                <li>
                  The fee is an annual subscription paid on 1<sup>st</sup> day
                  of July of every year by members registered as of 30
                  <sup>th</sup> June
                </li>
                <li>
                  For more on our rules kindly read this{" "}
                  <a href="/constitution">document</a>.
                </li>
              </ol>
            </div>

            <div className="userAgreement">
              <input
                type="checkbox"
                className="userAgreementBox"
                name="agreementcheck"
                value="inAgreement"
                checked={isInAgreement}
                onChange={(e) => setInAgreement(e.target.checked)}
              ></input>
              <p>
                I have read, understood , and accepted the rules of membership
              </p>
            </div>

            {/*Privacy Policy Part */}
            <div className="privacyPart">
              <h2>Privacy Policy</h2>
              <hr />
              <p></p>
              <div className="privacyPolicyAgreement">
                <p>
                  Please read our Privacy Policy in order to understand how your
                  information is used and shared, and check below if you accept
                  the policy.
                </p>
                <div className="privacyPolicyAgreementCheck">
                  <input
                    type="checkbox"
                    name="privacyCheck"
                    className="privacyCheck"
                    value="readPrivacy"
                    checked={hasReadPrivacy}
                    onChange={(e) => setReadPrivacy(e.target.checked)}
                  ></input>
                  <p>
                    I have read, understood, and accepted the{" "}
                    <a href="/privacy">Pivacy Policy</a> for the membership
                  </p>
                </div>
              </div>
            </div>

            {/*Final Note */}
            <div className="finalNote">
              <p>
                <i>
                  Once you submit your details, we will send you an email to
                  verify your email. Follow the link provided to complete your
                  registration.
                </i>
              </p>
              <p className="thankyouNote">
                <i>
                  <b>Thank You!</b>
                </i>
              </p>
            </div>

            <center>{error}</center>
            <div className="registrationFormBtnDiv">
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="registrationFormSubmitBtn"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    </section>
  );
};

export default Register;
