import React, { useEffect } from 'react';
import Login from '../components/Login/login';


const Loginpage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="Loginpage">
			<Login />
		</div>
	)
}

export default Loginpage;
