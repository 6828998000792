import React from "react";
import "./health.css";
import healthImg from "../../assets/health_cr.jpg";
import mentalHealthImg from "../../assets/hike3.jpg";
import Fit from "../../assets/fit1.jpg";
import { useNavigate } from "react-router-dom";

const Health = () => {
  const navigate = useNavigate();

  return (
    <section id="health">
      <div className="healthIntro">
        <h2 className="healthBenefitsTitle">
          Benefits of being active in sports to women aged 50+
        </h2>
        <p className="healthBenefitsText">
          There is global evidence that women who engage in physical activities
          cycling, running, walking, swimming, hiking, etc. are healthier and
          have lower risk of non-communicable diseases (NCDs), such as
          arthritis, rheumatic disease, cancers, diabetes, hypertension, among
          other lifestyle challenges.
        </p>
      </div>
      <div className="healthBenefits">
        <div className="firstBenefit" onClick={() => navigate("/blog")}>
          <img
            src={healthImg}
            alt="firstBenefitImg"
            className="firstBenefitImg"
          />
          <div className="firstBenefitContainer">
            <h3 className="firstBenefitTitle">Improved Overall Health</h3>
            <p className="firstBenefitText">
              Being active in sports for women especially those who are aged 50+
              has been proven to improve overall health and lower the risk of
              Non-Communicable Diseases (NCDs).
            </p>
          </div>
        </div>
        <div className="secondBenefit" onClick={() => navigate("/blog")}>
          <img
            src={mentalHealthImg}
            alt="mentalHealthImg"
            className="secondBenefitImg"
          />
          <div className="secondBenefitContainer">
            <h3 className="secondBenefitTitle">Mental Health</h3>
            <p className="secondBenefitText">
              Exercise helps improve mental health and reduce stress that comes
              due to life challenges including menopause and family situations
              that tend to make a major shift at this age.
            </p>
          </div>
        </div>
        <div className="thirdBenefit" onClick={() => navigate("/blog")}>
          <img src={Fit} alt="" className="thirdBenefitImg" />
          <div className="thirdBenefitContainer">
            <h3 className="thirdBenefitTitle">Physical Fitness</h3>
            <p className="thirdBenefitText">
              Through exercise, women are able to build muscle and improve their
              overall body fitness helping them prevent general body weakness
              that comes with aging
            </p>
          </div>
        </div>
      </div>
      <button type="button" className="healthBtn">
        <a href="/blog">Visit Our Blog</a>
      </button>
    </section>
  );
};

export default Health;
