import React, { useEffect, useState } from "react";
import "./memberlanding.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Memberlanding = () => {
  const navigate = useNavigate();

  const [FirstName, setFirstName] = useState("");
  const [Role, setRole] = useState("");
  let isAdmin = false;

  const url = "https://backend.goldengirlsonthemove.com";

  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem("token"));

    if (storedToken) {
      axios
        .get(`${url}/auth/get_profiledetails`, {
          headers: { Authorization: `Bearer ${storedToken.token}` },
          withCredentials: true,
        })
        .then((result) => {
          const memberProfileData = result.data.result[0];

          if (memberProfileData) {
            setFirstName(result.data.result[0]["FIRSTNAME"]);
            setRole(result.data.result[0]["ROLE"]);
          } else {
            console.error("Invalid response structure:", result.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  if (Role && Role === "admin") {
    isAdmin = true;
  }

  return (
    <section id="member__landing">
      <h3 className="welcome__msg">Welcome Back, {FirstName}</h3>
      <div className="landing__summary">
        <div className="summary__box">
          <h2>Summary</h2>
          <div className="payment__status">
            <h2>Payment Status</h2>
            <div className="payment__state">
              <FiberManualRecordIcon style={{ color: "red" }} /> <p> Pending</p>
            </div>
            <button
              className="pay__btn"
              onClick={() => navigate("/myaccount/payment")}
            >
              Pay Membership Fee
            </button>
          </div>
          <div>
            <p className="more__details">
              Please click on the links below to contact us incase or anything
              or to update your details:
              <br />
            </p>
            <div className="btn__box">
              <button
                className="support__btn"
                onClick={() => navigate("/myaccount/support")}
              >
                Contact Support
              </button>
              <button
                className="update__btn"
                onClick={() => navigate("/myaccount/profile")}
              >
                Update Profile
              </button>
              {isAdmin && (
                <button
                  className="admin__btn"
                  onClick={() => navigate("/dashboard")}
                >
                  Admin Dashboard
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="events__box">
          <h2>Upcoming Events</h2>
          <button className="events__boxBtn">
            No events yet. Stay tuned!!
          </button>
        </div>
      </div>
    </section>
  );
};

export default Memberlanding;
