import React from "react";
import Reset from "../components/Reset/reset";
import Footer from "../components/Footer/footer";

const Resetpassword = () => {
  return (
    <div>
      <Reset />
      <Footer />
    </div>
  );
};

export default Resetpassword;
