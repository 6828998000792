import React from "react";
import Dashboard from "../components/Dashboard/dashboard";

const AdminPage = () => {
  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default AdminPage;
