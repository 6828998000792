import React from "react";
import "./termsofuse.css";

const Termsofuse = () => {
  return (
    <div className="termsOfUse">
      <div className="general">
        <h2>GENERAL</h2>
        <hr />
        <p>
          Welcome to the Golden Girls on the move website. In these terms and
          consitions, "you" and "your" means you as the user of the website.
        </p>
      </div>
      <div className="acceptanceAgreement">
        <h2>ACCEPTANCE AND AGREEMENT</h2>
        <hr />
        <p>
          These terms and conditions ("Terms and Conditions") govern your use of
          the Website. By accessing the Website, you agree to these Terms and
          Conditions. If you do not agree to these Terms and Conditions, you
          should not use the Website.
          <br />
          <br /> You should read all the Terms and Conditions prior to using the
          Website. You should also save and/or print out a copy of these Terms
          and Conditions for future reference. The privacy of your personal
          information (such as your name, e-mail address, address and other
          contact details) is important to us. Please see our{" "}
          <a href="/privacy">Privacy Policy</a> for details of how we may
          process your personal information.
          <br />
          <br /> These Terms and Conditions, together with the Privacy Policy
          and the terms and conditions that apply to your use of particular
          areas of the Website (such as competitions) shall be collectively
          referred to as the "Agreement". This Agreement constitutes the entire
          agreement between you and us. If you are uncertain about any of its
          terms, please contact us by
          <a href="/contact">clicking here</a> or write to us at the address set
          out below and we will try to respond to your query as soon as
          possible.
        </p>
      </div>
      <div className="changesAgreement">
        <h3>CHANGES TO THIS AGREEMENT</h3>
        <hr />
        <p>
          We may from time to time make changes to the terms of this Agreement.
          Any changes will be posted on our Website. Please review these Terms
          and Conditions regularly to ensure you are aware of any changes made
          by us. If you use the Website after changes are notified to you in
          this way, you agree to such changes. If you do not agree to such
          changes, you should not use this Website.
        </p>
      </div>
      <div className="accessWebsite">
        <h3>ACCESS TO OUR WEBSITE</h3>
        <hr />
        <p>
          We may from time to time make changes to the terms of this Agreement.
          Any changes will be posted on our Website. Please review these Terms
          and Conditions regularly to ensure you are aware of any changes made
          by us. If you use the Website after changes are notified to you in
          this way, you agree to such changes. If you do not agree to such
          changes, you should not use this Website.
        </p>
      </div>
      <div className="contentUsage">
        <h3>USAGE OF OUR CONTENT</h3>
        <hr />
        <p>
          Subject to the terms of this Agreement, we grant you a non-exclusive,
          non-transferable, revocable, limited right to access, view, and print
          out one copy of this Website and all data, information, software
          graphics, images, text, posts and other content on the Website,
          ("Materials") on a single device strictly in accordance with this
          Agreement. <br />
          <br />
          You may only view, print out and use the Website and the Materials for
          your own personal, non-commercial use. <br />
          <br /> We expressly reserve all intellectual property rights in and to
          the Website and the Materials and your use of the Website and
          Materials is subject to the following restrictions. You must not{" "}
          <br />
          <ul>
            <li>
              (a) remove any copyright or other proprietary notices contained in
              the Materials;{" "}
            </li>
            <li>
              (b) save where expressly permitted by us to do so, modify the
              Materials in any way or reproduce them{" "}
            </li>
            <li>
              {" "}
              (c) publicly display, perform, or distribute or otherwise use the
              Materials for any public or commercial purpose;{" "}
            </li>
            <li>(c) transfer the Materials to any other person;</li>
            <li>
              (d) use any Materials from the Website in any manner that may
              infringe any intellectual property right, proprietary right, or
              property right of us or any third parties; or
            </li>{" "}
            <li>
              (e) reproduce, modify, display, perform, publish, distribute,
              disseminate, broadcast, frame, communicate to the public or
              circulate to any third party or exploit this Website and/or the
              Materials in any way; without our prior written consent.
            </li>
          </ul>{" "}
          <br />
          <br />
          Where we permit you to modify any of the Materials, we shall own all
          rights in the modified Material and you hereby assign to us all
          copyright and all other rights of whatever nature, whether now known
          or created in the future, in the modified Material for the whole term
          of the same including any renewals, reversions, revivals and
          extensions together with all related rights and powers. <br />
          <br /> You warrant that any modified Materials created by you shall be
          your original work and shall not infringe any laws or regulations or
          the rights of any person and shall not be obscene, threatening,
          abusive, harmful to others, invasive of another's privacy, hateful,
          harassing, discriminatory or otherwise objectionable.
        </p>
      </div>
      <div className="unacceptableContent">
        <h3>RULES ON UNACCAPTABLE CONTENT</h3>
        <hr />
        <p>
          If you find any content on our Website to be in any way offensive,
          obscene, defamatory, racist, harmful, inaccurate, unlawful, illegal,
          infringing of any third party rights, deceptive in any way or
          otherwise in breach of our content standards, please notify us by
          <a href="/contact">clicking here</a> and using the subject heading
          "Unacceptable content". We take complaints seriously and on receipt of
          your complaint we will investigate whether the content breaches our
          content standards or these terms and conditions and may, at our
          discretion, remove or block access to the content complained of.
        </p>
      </div>
      <div className="linkingToWebsite">
        <h3>LINKING TO OUR WEBSITE</h3>
        <hr />
        <p>
          You may link to our home page, provided you do so in a way that is
          fair and legal and which does not damage our reputation or take
          advantage of it. You must not link to our Website in such a way as to
          suggest any form of association, approval or endorsement on our part
          without our prior written consent. You must not remove or obscure by
          framing or otherwise, advertisements, copyright notices, or other
          information published on the Website. <br />
          <br />
          Our Website must not be framed on any other site. To avoid any doubt
          you must not link to our Website from a linking site which contains
          any adult or illegal material or any material that is offensive,
          harassing or otherwise objectionable. We reserve the right to withdraw
          linking permission without notice.
        </p>
      </div>
      <div className="uploadingContent">
        <h3>UPLOADING CONTENT TO OUR SITE</h3>
        <hr />
        <p>
          You agree not to submit any content to our Website that:
          <ul>
            {" "}
            <li>
              infringes any laws or regulations or the rights of others or
              encourages others to do so;
            </li>
            <li>
              is obscene, abusive, invasive of another's privacy, hateful,
              harassing, discriminatory or otherwise objectionable;
            </li>
            <li>impersonates another or is otherwise likely to deceive;</li>
            <li>
              is “spam” or any other form of unsolicited advertising,
              promotional material, or commercial message;
            </li>
            <li>
              contains or links to viruses or any other harmful programs or
              computer code designed to adversely affect the operation of any
              computer software or hardware; or
            </li>
            <li>disrupts or impairs the normal operation of the Website.</li>
          </ul>
        </p>
      </div>
      <div className="thirdPartyContent">
        <h3>THIRD PARTY SITE AND CONTENT</h3>
        <hr />
        <p>
          Our Website and/or the Materials may contain links to third party
          websites. If you decide to visit any third party site, you do so at
          your own risk. We are not responsible for the content, accuracy or
          opinions expressed on such websites. Links do not imply that we or
          this Website is/are affiliated or associated with such sites. Third
          party content may appear on the Website or may be accessible via links
          from the Website. We are not responsible for and assume no liability
          for such content.
        </p>
      </div>
      <div className="exclusionsDisclaimers">
        <h3>EXCLUSIONS AND DISCLAIMERS</h3>
        <hr />
        <p>
          Nothing in this Agreement shall exclude our liability for: <br />
          (a) death or personal injury arising through negligence;
          <br /> (b) fraudulent misrepresentation; and/or <br />
          (c) anything else that cannot be excluded or limited by us under the
          Kenyan law. <br />
          <br />
          The Materials may contain inaccuracies and typographical errors. We do
          not warrant the accuracy or completeness of the Materials. Any
          reliance on any such opinion, advice, statement, or other information
          shall be at your sole risk. We reserve the right, in our sole
          discretion, to correct any errors or omissions in any portion of the
          Website and to make any changes to the features, functionality or
          content of the Website at any time.
          <br />
          <br /> We reserve the right in our sole discretion to edit or delete
          any documents, information or other content on the Website. We do not
          represent or warrant that the Website will be error-free, free of
          viruses or owner harmful components, or that defects will be
          corrected.
        </p>
      </div>
    </div>
  );
};

export default Termsofuse;
