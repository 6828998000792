import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Breadcrum from "../Breadcrums/breadcrum";
import { ShopContext } from "../../context/shopcontext";
import ProductDisplay from "../ProductDisplay/productdisplay";
import DescriptionBox from "../Description/description";
import RelatedProducts from "../RelatedProducts/relatedproducts";

const Product = () => {
  const { data_product } = useContext(ShopContext);
  const { productId } = useParams();
  const product = data_product.find((e) => e.id === Number(productId));
  return (
    <div>
      <Breadcrum product={product} />
      <ProductDisplay product={product} />
      <DescriptionBox />
      <RelatedProducts />
    </div>
  );
};

export default Product;
