import React from "react";
import "./news.css";
import NewsBg from "../../assets/events_bg1.jpg";

const News = () => {
  return (
    <section id="news">
      <div>
        <div className="news__content">
          <div className="news__content__Info">
            <h3>GG-M EVENTS</h3>
            <p>
              Explore the latest news and gain insight on the activities
              undertaken in our aim to empower women aged 50+ to engage in
              sports and physical activites. We also take parts in various
              social intiatives and events geared towards addressing various
              issues affecting women in this age group.
            </p>
          </div>
          {/* <div className="news_img">
            <img src={NewsBg} alt="news_img" className="news_img1" />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default News;
