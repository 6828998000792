import React from "react";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";
import { NavLink, Outlet } from "react-router-dom";
/* import GGM from "../../assets/try_3-transformed.png"; */
import Adminprofile from "../../assets/user-avatar.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
/* import HomeIcon from "@mui/icons-material/Home"; */
import MailIcon from "@mui/icons-material/Mail";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PeopleIcon from "@mui/icons-material/People";
import EventIcon from "@mui/icons-material/Event";
import Face3Icon from "@mui/icons-material/Face3";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import LogoutIcon from "@mui/icons-material/Logout";

function Dashboard() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear token from localStorage
    localStorage.removeItem("token");

    // Redirect to login page
    navigate("/login");
  };

  const SidebarData = [
    {
      title: "Dashboard",
      icon: <DashboardIcon />,
      Link: "/dashboard",
    },
    {
      title: "Manage Members",
      icon: <PeopleIcon />,
      Link: "/dashboard/manage",
    },
    {
      title: "Create Event",
      icon: <EventIcon />,
      Link: "/dashboard/createevent",
    },
    {
      title: "Email Members",
      icon: <MailIcon />,
      Link: "/dashboard/sendemail",
    },
    {
      title: "Analytics",
      icon: <AssessmentIcon />,
      Link: "/dashboard/analytics",
    },
    {
      title: "Visit Website",
      icon: <OpenInBrowserIcon />,
      Link: "/home",
    },
    {
      title: "Your Profile",
      icon: <Face3Icon />,
      Link: "/myaccount/profile",
    },
    {
      title: "Log Out",
      icon: <LogoutIcon />,
      onClick: handleLogout, // Add onClick handler here
    },
  ];

  return (
    <div className="sidebarContent">
      <div className="sidebar">
        <div className="adminInfo">
          <img
            src={Adminprofile}
            alt="dashboardLogo"
            className="adminIcon"
          ></img>
          <h4 className="sidebaeAdminHead">Admin</h4>
        </div>
        <hr />
        <ul className="sidebarList">
          {SidebarData.map((val, key) => (
            <li key={key} className="sidebarListItem">
              {val.title === "Log Out" ? (
                <button className="sidebarListItemBtn" onClick={val.onClick}>
                  <div id="sidebarIcon">{val.icon}</div>
                  <div id="sidebarTitle">{val.title}</div>
                </button>
              ) : (
                <NavLink
                  to={val.Link}
                  className={({ isActive }) =>
                    `${isActive ? "sidebarListItem active" : "sidebarListItem"}`
                  }
                >
                  <div id="sidebarIcon">{val.icon}</div>
                  <div id="sidebarTitle">{val.title}</div>
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="dashboard__content">
        <div className="dashboard__header">
          <h2 className="dashboardHeader">
            Welcome to the Management Dashboard
          </h2>
        </div>
        <hr />
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
