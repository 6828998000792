import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/footer';

const Nopage = () => {
  useEffect(() => {
    const element = document.getElementById("noPageDisplay");

    if (element) {
      element.style.color = 'red';
      element.style.height = '100vh';
    }
  }, []);

  return (
    <section id='noPage'>
      <Navbar />
      <h2 id='noPageDisplay'>No Page Found</h2>
      element.style.color = 'red';
      element.style.padding = 30rem;
      <Footer />
    </section>
  )
};

export default Nopage;
