import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/footer';
import Ggmblog from '../components/Blog/ggm_blog';

const Blog = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Navbar />
			<Ggmblog />
			<Footer />
		</div>

	)
};

export default Blog;
