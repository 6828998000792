import React from "react";
import "./addmember.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Addmember = () => {
  const areaCodes = ["+254", "+255", "+256" /* Add more area codes */];
  const years = [];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [];

  const firstYear = new Date().getFullYear() - 49;
  let i = 50;

  while (i) {
    years.unshift(firstYear - i);
    i--;
  }

  let dy = 31;

  while (dy) {
    days.unshift(dy);
    dy--;
  }

  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [secondname, setSecondName] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [streetname, setStreetName] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [county, setCounty] = useState("");
  const [city, setCity] = useState("");
  const [areaCode, setSelectedAreaCode] = useState("");
  const [month, setSelectedMonth] = useState("");
  const [year, setSelectedYear] = useState("");
  const [day, setSelectedDay] = useState("");
  const isFriendOrColleague = false;
  const isFacebook = false;
  const isYouTube = false;
  const isTikTok = false;
  const isGoldenMember = false;
  const isGGMEvent = false;
  const isOther = false;
  const [preferredCommunication, setPreferredCommunication] = useState("any"); // Set a default value
  const isInAgreement = false;
  const hasReadPrivacy = false;

  const [error, setError] = useState("");

  //Navigate

  const navigate = useNavigate();

  //Generate user password
  const generateRandomPassword = (length) => {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }
    return password;
  };

  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${
    currentDate.getMonth() + 1
  }-${currentDate.getDate()}`;

  //Post registeration Info
  const axiosCreateMember = async () => {
    const memberPassword = generateRandomPassword(10);

    console.log(memberPassword);
    const dateJoined = formattedDate;
    const birthdate = `${year}-${month}-${day}`;

    const addMemberData = {
      FirstName: firstname,
      LastName: lastname,
      OtherName: secondname,
      Email: email,
      MemberPassword: memberPassword,
      AreaCode: areaCode,
      PhoneNumber: phonenumber,
      NationalID: nationalId,
      StreetAddress: streetname,
      CountyState: county,
      City: city,
      ZipCode: zipcode,
      Country: country,
      DateOfBirth: birthdate,
      FriendorColleague: isFriendOrColleague,
      Facebook: isFacebook,
      YouTube: isYouTube,
      TikTok: isTikTok,
      GoldenMember: isGoldenMember,
      GGMEvent: isGGMEvent,
      Other: isOther,
      PreferredCommunication: preferredCommunication,
      Agreement: isInAgreement,
      ReadPrivacy: hasReadPrivacy,
      DateJoined: dateJoined,
    };

    console.log(addMemberData);

    const url = "https://backend.goldengirlsonthemove.com";

    await axios
      .post(`${url}/auth/add_member`, addMemberData)
      .then((result) => {
        if (result.data.Status) {
          navigate("/dashboard");
        } else {
          alert(result.data.Error);
        }
      })
      .catch((err) => {
        console.error("Axios error:", error);
        alert("Error adding member.");
      });
  };

  /* Handle Submit Function */
  const handleSubmit = (e) => {
    e.preventDefault();

    const selectedValue = e.target.value;
    setPreferredCommunication(selectedValue);

    if (!isInAgreement) {
      setError(
        <p className="required">Kindly agree to our rules to register.</p>
      );
    } else {
      setError("");
    }

    //Posting form data
    setError("");
    axiosCreateMember();
  };

  /* Return section containing registration form */
  return (
    <section id="addmemberPage">
      <h4
        style={{
          margin: "2rem auto",
          textAlign: "center",
          fontSize: "1.5rem",
          fontFamily: "Recoleta",
          padding: "2rem",
        }}
      >
        Create Golden Member
      </h4>
      <div className="addmemberInfo">
        <form className="addmemberForm">
          <h4>Name</h4>
          <div className="nameSection">
            <div className="nameSectionPart">
              <input
                type="text"
                className="firstname"
                placeholder="First Name"
                name="firstname"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
              ></input>
              <p>First Name</p>
            </div>
            <div className="nameSectionPart">
              <input
                type="text"
                className="surname"
                placeholder="Surname"
                name="lastname"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
              ></input>
              <p>Last Name</p>
            </div>
            <div className="nameSectionPart">
              <input
                type="text"
                className="middlename"
                placeholder="Other name"
                name="secondname"
                value={secondname}
                onChange={(e) => setSecondName(e.target.value)}
              ></input>
              <p>Other Name</p>
            </div>
          </div>

          <h2
            style={{
              textAlign: "center",
              fontSize: "1.4rem",
              fontFamily: "Recoleta",
              padding: "1.5rem",
              marginTop: "1rem",
              color: "#008080",
            }}
          >
            Contact Information
          </h2>

          <div className="contactsSection">
            <div className="emailPart">
              <h4>Email</h4>
              <input
                type="email"
                className="yourEmail"
                placeholder="Enter your email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div className="phonenumberSection">
              <h4>Phone Number</h4>
              <div className="contactSelectionPart">
                <div className="areacodeSelection">
                  <select
                    className="areaCode"
                    name="areacode"
                    value={areaCode}
                    onChange={(e) => setSelectedAreaCode(e.target.value)}
                  >
                    <option value=""></option>
                    {areaCodes.map((code, index) => (
                      <option key={index} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                  <p>Area Code</p>
                </div>
                <div className="phoneNumberPart">
                  <input
                    type="text"
                    className="PhoneNumber"
                    name="phonenumber"
                    value={phonenumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  ></input>
                  <p>Phone Number</p>
                </div>
              </div>
            </div>
            <div className="IdPart">
              <h4>ID/Passport no.</h4>
              <input
                type="text"
                className="IdNumber"
                name="nationalId"
                value={nationalId}
                onChange={(e) => setNationalId(e.target.value)}
              ></input>
            </div>
          </div>

          {/* Location Part */}
          <div className="memberLocation">
            <h2
              style={{
                textAlign: "left",
                fontSize: "1.3rem",
                fontFamily: "Recoleta",
                padding: "1.5rem",
                marginTop: "1rem",
              }}
            >
              Address
            </h2>
            <div className="memberStreetNameDiv">
              <p>Street Address</p>
              <input
                type="text"
                className="streetName"
                name="streetname"
                value={streetname}
                onChange={(e) => setStreetName(e.target.value)}
              ></input>
            </div>
            <div className="memberCityCountyDiv">
              <div className="memberCountyDiv">
                <p>County/State</p>
                <input
                  type="text"
                  className="county"
                  name="county"
                  value={county}
                  onChange={(e) => setCounty(e.target.value)}
                ></input>
              </div>
              <div className="cityDiv">
                <p>City</p>
                <input
                  type="text"
                  className="city"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="memberZipCountryDiv">
              <div className="memberZipDiv">
                <p>Postal/ Zip Code</p>
                <input
                  type="alpanum"
                  className="postOffice"
                  name="zipcode"
                  value={zipcode}
                  onChange={(e) => setZipCode(e.target.value)}
                ></input>
              </div>
              <div className="countryDiv">
                <p>Country</p>
                <input
                  type="dropdown"
                  className="Country"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                ></input>
              </div>
            </div>
          </div>

          {/* Personal Information Part */}
          <h4
            style={{
              textAlign: "center",
              fontSize: "1.3rem",
              fontFamily: "Recoleta",
              padding: "1.5rem",
              marginTop: "1rem",
            }}
          >
            Date of Birth
          </h4>
          <div className="memberPersonalInfo">
            <div className="personalInfoPart">
              <select
                className="month"
                name="month"
                value={month}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value=""></option>
                {months.map((code, index) => (
                  <option key={index} value={code}>
                    {code}
                  </option>
                ))}
              </select>
              <p>Month</p>
            </div>
            <div className="personalInfoPart">
              <select
                className="day"
                name="day"
                value={day}
                onChange={(e) => setSelectedDay(e.target.value)}
              >
                <option value=""></option>
                {days.map((code, index) => (
                  <option key={index} value={code}>
                    {code}
                  </option>
                ))}
              </select>
              <p>Day</p>
            </div>
            <div className="personalInfoPart">
              <select
                className="year"
                name="year"
                value={year}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value=""></option>
                {years.map((code, index) => (
                  <option key={index} value={code}>
                    {code}
                  </option>
                ))}
              </select>
              <p>Year</p>
            </div>
          </div>

          {/* Communication Method Part */}
          <div className="communication">
            <h4>Prefered way of communication</h4>
            <div className="commBars">
              <input
                type="radio"
                className="commMethod"
                name="communication"
                value="email"
                checked={preferredCommunication === "email"}
                onChange={(e) => setPreferredCommunication("email")}
              />
              <p>Email</p>
            </div>
            <div className="commBars">
              <input
                type="radio"
                className="commMethod"
                name="communication"
                value="phone"
                checked={preferredCommunication === "phone"}
                onChange={(e) => setPreferredCommunication("phone")}
              />
              <p>Phone</p>
            </div>
            <div className="commBars">
              <input
                type="radio"
                className="commMethod"
                name="communication"
                value="any"
                checked={preferredCommunication === "any"}
                onChange={(e) => setPreferredCommunication("any")}
              />
              <p>Any</p>
            </div>
          </div>

          <center>{error}</center>
          <div className="registrationFormBtnDiv">
            <button
              type="submit"
              onClick={handleSubmit}
              className="registrationFormBtn"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Addmember;
