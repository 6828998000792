import React from "react";
import "./constitution.css";

const Constitution = () => {
  return (
    <div>
      <div className="constitution">
        <div className="constitutionDetails">
          <h2>
            CONSTITUTION OF GOLDEN GIRLS ON THE MOVE NATIONAL SPORTING
            ORGANIZATION, KENYA
          </h2>
          <ol className="constitutionList">
            {/** List Item 1 */}
            <li className="majorListItem">
              <h3>NAME</h3>{" "}
              <p>
                The name shall be ‘Golden Girls on the Move (GG-M) Sports Club’
                hereinafter referred to as the Club.
              </p>
            </li>{" "}
            {/**List item 2 */}
            <li className="majorListItem">
              <h3>NATURE OF THE CLUB</h3>
              <p>
                The Club shall be a non-political and non-profit making
                organization.{" "}
              </p>
            </li>
            {/**List item 3 */}
            <li className="majorListItem">
              <h3>OBJECTS</h3>
              <p>The objects for which the Club is established are to:</p>
              <ol className="innerList">
                <li className="innerListItem">
                  Support and encourage all women aged 50+ to be physically
                  active for good health and wellbeing;
                </li>
                <li className="innerListItem">
                  {" "}
                  Provide opportunities for Golden Girls to learn and thrive;
                </li>
                <li className="innerListItem">
                  Nurture talent among young sportswomen in all aspects of their
                  lives: physical; mental; social; and spiritual;
                </li>
                <li className="innerListItem">
                  Engage in philanthropic activities that target women;
                </li>
                <li>
                  Organize sports events with a view to to raising awareness on
                  women in sports; and{" "}
                </li>
                <li className="innerListItem">
                  Liaise with other local and international organizations on
                  matters of mutclassName="innerListItem"ual interest to the
                  members.
                </li>
              </ol>
            </li>
            {/**list item 4 */}
            <li className="majorListItem">
              <h3>MEMBERSHIP</h3>
              <ol className="innerList">
                {" "}
                <li className="innerListItem">
                  Membership is open to any woman aged 50+ years who is engaged
                  in sports or who has an interest in sports in the country.
                  Members will be required to pay an annual subscription fee of
                  KShs. 3,000.00 or as may be prescribed by the Management
                  Committee from time to time. The sum shall become due and
                  payable on the 1st day of July in each year by all members on
                  the register of the Club on the preceding 30 th day of June.
                </li>
                <li className="innerListItem">
                  Any person may be allowed to participate in the meetings and
                  other activities of the Club as a guest but subject to such
                  conditions as the Committee may prescribe.
                </li>
              </ol>
            </li>
            {/**list item 5 */}
            <li className="majorListItem">
              {" "}
              <h3>LOSS OF MEMBERSHIP</h3>{" "}
              <p>Membership of the Club shall be lost:</p>
              <ol className="innerList">
                <li className="innerListItem">
                  By withdrawal, which shall be notified to the Secretary in
                  writing, and shall be deemed to take effect immediately such
                  notification is received. Committee members will be required
                  to give one month notice in writing. No refund of subscription
                  shall be made.
                </li>
                <li className="innerListItem">
                  By a two thirds decision of a Special General Meeting to
                  exclude any member, where the attitude or action by the member
                  is considered detrimental to the reputation or interest of the
                  Club or whose behaviour casts P.0 Box 5043-00100 Nairobi,
                  Kenya Email: goldengirls.move@gmail.com 2 discredit upon the
                  Club. No exclusion may be founded upon reasons of a racial,
                  religious, political or marital nature.{" "}
                </li>
                <li className="innerListItem">
                  Automatically if the annual subscription is not paid within
                  six months of being due, unless the Management Committee is
                  satisfied that the delay in payment is due to a sufficient
                  cause. Membership may be reinstated at any time at the
                  discretion of the Committee and upon payment of all arrears.
                </li>
              </ol>
            </li>
            {/**List item 6 */}
            <li className="majorListItem">
              <h3>COMMITTEE</h3>
              <h4>
                <b>6.1 The Management</b>
              </h4>
              <ol className="innerInnerCommitteeList">
                <li className="innerInnerListCommitteeItem">
                  The Management of the Club shall be entrusted to a Committee
                  of seven (7) elected members of which the Executive Committee
                  shall comprise:
                  <ol className="committeeList">
                    {" "}
                    <li className="committeeListItem">
                      {" "}
                      Chairperson, vice-chairperson, Secretary, Treasurer,
                      Events Coordinator; Advocacy and Publicity, and County
                      Liaison.{" "}
                    </li>
                    <li className="committeeListItem">
                      The Committee may recruit officers and prescribe their
                      duties, powers, responsibilities, remuneration and
                      procedures in a manner it deems fit;
                    </li>
                    <li className="committeeListItem">
                      The Committee will recruit a CEO who will provide
                      leadership at the Secretariat for the Club.
                    </li>
                  </ol>
                </li>
                <li className="innerInnerCommitteeListItem">
                  The Committee shall be in office three years, renewable once.{" "}
                </li>
              </ol>
              <h4>
                <b>6.2 Duties of the committee</b>
              </h4>
              <ol className="innerInnerCommitteeList">
                {" "}
                <li className="innerInnerListCommitteeItem">
                  It shall be the duty of the Committee to coordinate and
                  control the affairs of the Club, regulate its membership and
                  safeguard the interests of the members.
                </li>
                <li className="innerInnerListCommitteeItem">
                  The Committee shall have control over the funds of the Club
                  and shall defray all expenses therefrom.
                </li>
                <li className="innerInnerListCommitteeItem">
                  The Committee shall have responsibility of ensuring that the
                  members adhere to the rules of the Club.
                </li>
                <li className="innerInnerListCommitteeItem">
                  In the event of any matter or question arising, which is not
                  covered wholly or in part by the Rules of the Club for the
                  time being in force, then the Chairperson, after due
                  deliberation with other Committee members, shall convene a
                  Special General Meeting during which they shall submit their
                  proposal for ratification.
                </li>
                <li className="innerInnerListCommitteeItem">
                  The Committee shall have the power to appoint sub-committees
                  as it may deem necessary to make reports to the Committee on
                  specific matters upon which such action, as considered
                  appropriate, shall be taken by the committee.{" "}
                </li>
              </ol>
            </li>
            <li className="majorListItem">
              <h3>PATRON FOR THE ASSOCIATION</h3>
              <p>
                There shall be a Patron of the Club who shall be an eminent
                female personality who has demonstrated commitment to sports and
                a healthy lifestyle and has a track record of nurturing talent
                among women of all ages.
              </p>
            </li>
            <li className="majorListItem">
              <h3>ELECTIONS</h3>{" "}
              <ol className="innerList">
                <li className="innerListItem">
                  {" "}
                  All elections shall be by nomination seconding and secret
                  ballot at the AGM.
                </li>
                <li className="innerListItem">
                  The result of the ballot shall be binding as declared by the
                  Returning Officer.
                </li>
                <li className="innerListItem">
                  Where there is a tie between two nominees, the Chairperson
                  shall have a casting vote.
                </li>
                <li className="innerListItem">
                  The Chairperson and the Committee members shall be elected
                  directly by members. The elected Committee members shall
                  appoint the executive Committee as noted under Clause 6 above
                  within fourteen days of their election.
                </li>
                <li className="innerListItem">
                  One-third of the members of the Committee shall retire every 3
                  years and shall be eligible for re-election.
                </li>
                <li className="innerListItem">
                  One-third of the members of the Committee shall retire every
                  three years and shall not be eligible for re-election.
                </li>
              </ol>
            </li>
            <li className="majorListItem">
              <h3>MEETINGS</h3>{" "}
              <ol className="innerList">
                <li className="innerListItem">
                  The Committee shall meet at least four times a year at such
                  time and place as its members shall determine and at such
                  meetings, five (5) members shall form a quorum.
                </li>
                <li className="innerListItem">
                  The Annual General Meeting (AGM) shall be held not later than
                  31 st March in each year. Written Notice of the AGM signed by
                  the Secretary, accompanied by the Annual audited accounts, and
                  the Agenda of the meeting shall be sent to all members not
                  less than 21 days before the date of the meeting.
                </li>
                <li className="innerListItem">
                  A Special General Meeting may be called for a specific purpose
                  by the Committee. Written Notice of such meeting signed by the
                  Secretary shall be sent to all members not less than 14 days
                  before the date thereof.
                </li>
                <li className="innerListItem">
                  A Special General meeting may be called for a specific purpose
                  by a request in writing to the Secretary by not less than 10
                  members. Such meetings shall be held within 21 days of the
                  date of the request. The notice of such meeting shall be as
                  shown in rule 8 (iii). No matter shall be discussed other than
                  that stated in the requisition.
                </li>
                <li className="innerListItem">
                  Quorum for General Meetings shall be not less than one-third
                  of the registered members of the Clubpresent in person or by
                  proxy.
                </li>
                <li>
                  At all meetings of the Clubthe Chairperson, or in her absence,
                  the Vice- Chairperson, shall preside or in the absence of
                  both, a Chairperson shall be elected from among members
                  present for the duration of the meeting.
                </li>
                <li className="innerListItem">
                  At any Annual General or Special General Meeting of the Club,
                  a resolution put to the vote shall be decided on a show of
                  hands unless a poll is demanded by at least one-third of those
                  present in person or by proxy and eligible to vote.
                </li>
                <li className="innerListItem">
                  If a poll is duly demanded,it shall be taken ins such manner
                  as the Chairperson shall direct, and the result of the poll
                  shall be deemed to be the resolution of that particular
                  meeting.
                </li>
                <li className="innerListItem">
                  The Chairperson shall have a casting vote in (vii) and (viii)
                  above. 10.
                </li>
              </ol>
            </li>
            <li className="majorListItem">
              <h3>FINANCE</h3>
              <ol className="innerList">
                {" "}
                <li className="innerListItem">
                  The Club’s revenue shall be derived from member’s
                  subscriptions, donations and fund raising activities.
                </li>
                <li className="innerListItem">
                  All monies and funds shall be shall be deposited directly into
                  the bank in the name of the Club (the bank details shall be
                  provided to all members upon completing the registration
                  form).
                </li>
                <li className="innerListItem">
                  All cheques shall be signed by the Treasurer, or the CEO and
                  counter- signed either by the Chairperson or an appointed
                  member of the Management Committee.
                </li>
                <li className="innerListItem">
                  The funds of the Club may only be used to finance the
                  activities of the Club and to promote the objectives laid down
                  in item (3)of this constitution.
                </li>
                <li className="innerListItem">
                  The CEO/other officers shall maintain Petty Cash float for
                  Petty disbursements of which proper account shall be kept. The
                  amount of the cash float shall be determined by the Management
                  Committee from time to time, as deemed necessary.
                </li>
                <li className="innerListItem">
                  Should the Committee have reasonable cause to believe that any
                  member is not properly accounting for the Club’s funds, the
                  Committee may suspend that member and appoint another
                  committee member in her place. Such suspension shall be
                  reported to a Special General Meeting to be convened on a date
                  not later than three months from the date of such 5
                  suspension. The Special General Meeting shall have full power
                  to decide on any further action to be taken.
                </li>
                <li className="innerListItem">
                  The financial year of the Club shall be from July 01 st to
                  June to 30 th.{" "}
                </li>
              </ol>
            </li>
            <li className="majorListItem">
              {" "}
              <h3>AUDITOR</h3>
              <ol className="innerList">
                {" "}
                <li className="innerListItem">
                  The records of the Club shall be audited annually by an
                  Auditor who shall be appointed by the members during the
                  Annual General Meeting.
                </li>
                <li className="innerListItem">
                  No member of the Committee of the Club shall be eligible for
                  appointment as Auditor.
                </li>
                <li className="innerListItem">
                  The Auditor shall retire at every Annual General Meeting but
                  shall be eligible for re-appointment.
                </li>
              </ol>
            </li>
            <li className="majorListItem">
              <h3>INSPECTION OF ACCOUNTS AND LIST OF MEMBERS</h3>
              <p>
                The books of Accounts and all documents relating there-to and a
                list of members of the Club shall be available for inspection at
                the registered office of the Club by any officer or member of
                the Club on giving not less than seven days notice in writing to
                the Club.{" "}
              </p>
            </li>
            <li className="majorListItem">
              <h3>CHAPTERS</h3>
              <ol className="innerList">
                {" "}
                <li className="innerListItem">
                  With the approval of the Committee and the Registrar of
                  Societies, chapters of the Club may be formed in the counties
                  where there are more than ten members. Such chapters shall be
                  governed by the provisions of this Constitution.
                </li>
                <li className="innerListItem">
                  Amendments to the Constitution can only be made by the
                  headquarters of the Club in accordance with the provision of
                  rule 13.
                </li>
                <li className="innerListItem">
                  The provisions of rule 14 shall apply to chapters but, in
                  addition, chapters will not be dissolved without consultation
                  with the headquarters.
                </li>
              </ol>
            </li>
            {/** List Item 14 */}
            <li className="majorListItem">
              <h3>AMENDMENTS</h3>
              <p>
                The Constitution of the Club may be rescinded, amended or added
                only by a resolution passed by a two-thirds majority of total
                registered members at Annual General or Special General Meeting
                and upon receipt of consent from the Registrar of Societies.
              </p>
            </li>
            <li className="majorListItem">
              {" "}
              <h3>DISSOLUTION</h3>{" "}
              <ol className="innerList">
                <li className="innerListItem">
                  The Club shall not be dissolved except by a resolution passed
                  at Annual or Special General Meeting of members by vote of
                  two-thirds of the members present. The quorum at the meeting
                  shall be as shown in rule 8 (v). If no quorum is obtained, the
                  proposal to dissolve the Club shall be submitted to a further
                  General Meeting, which shall be held one month later. Notice
                  of this meeting shall be given to all members of the Club at
                  least 14 days before the date of the meeting. The quorum for
                  this meeting shall be the number of members present.
                </li>
                <li className="innerListItem">
                  Provided however, that no dissolution shall be effected
                  without prior permission in writing by the Registrar of
                  Societies. Application to dissolve the Club shall be made to
                  the Registrar in writing and signed by any three of the
                  Committee members.
                </li>
                <li className="innerListItem">
                  Once the dissolution of the Club is approved by the Registrar,
                  no further action shall be taken by the Committee or any
                  officer of the Club in connection with the objects of the
                  Society other than to get in and liquidate for cash all the
                  assets of the Club. Subject to the payment of all the debts 6
                  of the Club, any balance thereof shall be utilized in such
                  other manner as may be resolved by the meeting at which the
                  resolution for dissolution is passed. No distribution shall be
                  made to the members.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Constitution;
