import React from 'react';
import './createevent.css';

const CreateEvent = () => {
  return (
	<section id='createevent'>
		<div className='eventBars'>
			Create Event
		</div>  
    </section>
  )
}

export default CreateEvent;
